<template>
    <div class="container-fluid">
        <div class="d-flex">
            <h3 class="text-primary">Service</h3>

            <button class="btn btn-primary ml-auto" @click="showBillableItem()">
                <i class="fas fa-plus"></i> Service
            </button>
        </div>
        <div class="input-group mt-2 mb-2">
            <input type="text" class="form-control" focus
                placeholder="Scan Barcode or search by ID, generic name, brand name" v-model="keyword2" />
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fas fa-search"></i>
                </span>
            </div>
        </div>

        <vue-good-table :columns="BillableItemColumns" :rows="filteredBillableItems" @on-row-click="showBillableItem"
            :pagination-options="{
                enabled: true,
            }" />
        <b-modal id="billable-item-modal" hide-footer size="xl" :title="'Service'">
            <div class="row">
                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Item code'" v-model="billable_item.item_code" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Item name'" v-model="billable_item.item_name" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Item type'" v-model="billable_item.item_type" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Price (Thai)'" v-model="billable_item.price_th" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="billable_item.price_inter" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Price (Insurance)'"
                        v-model="billable_item.price_insurance" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Quantity'" v-model="billable_item.quantity" />
                </div>
                <div class="col-12 mt-2">
                    <button class="btn btn-primary btn-block" @click="saveBillableItem">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
                <div class="col-12 mt-2" v-if="isEditBillableItem">
                    <button class="btn btn-danger btn-block" @click="deleteBillableItem(billable_item.item_code)">
                        <i class="fas fa-trash"></i> Delete Billable Item
                        {{ billable_item.item_code }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { db, Timestamp } from "../../db"

export default {
    components: {

    },
    data() {
        return {
            keyword2: "",
            billable_items: [],
            billable_item: {},
            isEditBillableItem: false,
            BillableItemColumns: [
                {
                    label: "Item Code",
                    field: "item_code",
                },
                {
                    label: "Item Name",
                    field: "item_name",
                },
                {
                    label: "Item Type",
                    field: "item_type",
                },
                {
                    label: "Price",
                    field: "price_inter",
                    thClass: 'text-right',
                    tdClass: 'text-right',
                },
            ],
        }
    },
    firestore() {
        return {
            billable_items: db.collection("BillableItem"),
        }
    },
    methods: {
        showBillableItem(params) {
            if (params) {
                console.log({ params })
                this.billable_item = { ...params.row }
                this.isEditBillableItem = true
            } else {
                this.isEditBillableItem = false
                this.billable_item = {}
            }
            this.$bvModal.show("billable-item-modal")
        },
        async saveBillableItem() {
            let loader = this.$loading.show({})
            if (!this.billable_item.item_code || !this.billable_item.item_name) {
                this.$alert("please enter item code and item name", null, "error")
                loader.hide()
            }

            try {
                let billable_item = { ...this.billable_item }
                await db
                    .collection("BillableItem")
                    .doc(this.billable_item.item_code)
                    .set(billable_item)
                loader.hide()
                this.$alert("success", null, "success")
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
        },
    },
    computed: {

        filteredBillableItems() {
            let items = []
            if (this.keyword2) {
                for (let item of this.billable_items) {
                    if (
                        item.id.indexOf(this.keyword2.toUpperCase()) !== -1 ||
                        (item.item_name &&
                            item.item_name
                                .toUpperCase()
                                .indexOf(this.keyword2.toUpperCase()) !== -1)
                    )
                        items.push(item)
                }
                return items
            } else {
                return this.billable_items
            }
        },


    },
}
</script>