<template>
  <div class="sheet p-4">
    <div class="container-fluid">
      <payment-table :data="payments" />
      <p class="text-grey mt-2 text-right" v-if="start && end">{{ $route.query.start }} to {{ $route.query.end }}</p>
    </div>
  </div>
</template>

<script>
import { db } from '../../db'
import { capitalizeFirstChar } from '../../helper'
import moment from 'moment'
export default {
  data() {
    return {
      payments: [],
      start: this.$route.query.start,
      end: this.$route.query.end,
      patientId: this.$route.query.patientId,
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const payments = []
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const start = decodeURIComponent(urlParams.get('start'))
      const end = decodeURIComponent(urlParams.get('end'))
      
      const startDate = moment(start, 'DD/MM/YYYY').startOf('day').toDate()
      const endDate = moment(end, 'DD/MM/YYYY').endOf('day').toDate()
      
      const paymentsCollection = this.patientId ?
        await db.collection('Payment')
          .where('HN', '==', this.patientId)
          .get()
        :
        await db.collection('Payment')
          .where('issue_date', '>', startDate)
          .where('issue_date', '<', endDate)
          .get()
      
          for (let i = 0; i < paymentsCollection.docs.length; i++) {
        const paymentCollection = paymentsCollection.docs[i]
        const payment = paymentCollection.data()      
        const preparedPayments = []        
        if (payment.cash > 0) {
          preparedPayments.push({ name: 'Cash', amount: payment.cash })
        }
        if (payment.credit > 0) {
          preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        }
        if (payment.insurance > 0) {
          preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        }
        if (payment.internet_banking > 0) {
          preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        }
        if (payment.other > 0) {
          preparedPayments.push({
            name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other })
        }        
        payment.total_amount = [payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking].reduce((sum, value) => sum + Number(value || 0), 0)
        let invoice = (
        await db
          .collection("Invoice")
          .where("invoice_id", "==", payment.invoice_id)
          .get()
        ).docs.map((invoice) => invoice.data())
        
        payment.visit_date = invoice[0].visit_date
        
        payment.preparedPayments = preparedPayments       
        payments.push(payment)
      }
      
      const paymentsByDate = _.groupBy(payments, payment => {
        return this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY")
      })

      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments
      }))

      loader.hide()
    },
  }
}
</script>

<style type="text/css" media="print">
@page {
  size: landscape !important
}
</style>