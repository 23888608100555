<template>
  <div>
    <section class="sheet padding-10mm invoice-form">
      <div class="d-flex">
        <img
          v-if="$store.state.branch == 3"
          src="/images/wa-logo.png"
          class="img-fluid mx-auto"
          style="width: 94px;"
        />
        <img
          v-else
          src="/images/worldmed-logo-2.jpg"
          class="img-fluid mx-auto"
          style="width: 94px;"
        />
      </div>
      <div class="mt-2 d-flex justify-content-between">
        <div>
          <div>
            <strong>Patient Name:</strong>
            <p class="text-red-700">{{ invoice.patient_name }}</p>
          </div>
          <div class="mt-2">
            <strong>Billed to:</strong>
            <p class="text-red-700">{{ invoice.billed_to }}</p>
          </div>
        </div>
        <div style="width: 40%;">
          <div class="d-flex">
            <strong class="text-primary w-25">Invoice #:</strong>
            <p class="text-red-700 ml-5">{{ invoice.invoice_id }}</p>
          </div>
          <div class="d-flex">
            <strong class="w-25">Date Issued</strong>
            <p class="text-red-700 ml-5" v-if="invoice.issue_date">{{ invoice.issue_date.toDate() | moment('DD/MM/YYYY HH:mm') }}</p>
          </div>
          <div class="d-flex">
            <strong class="w-25">Practitioner</strong>
            <p class="text-red-700 ml-5">{{ invoice.practitioner }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex mt-1 border-bottom">
        <strong>Appointment Date: </strong>
        <p class="ml-2">24 Jan 2021</p>
      </div> -->
      <short-product-table :data="invoice" v-if="$route.query.mode==='short'"/>
      <HidePriceProductTable :data="invoice" v-else-if="$route.query.mode==='hide'"/>
      <product-table :data="invoice" v-else/>
      <div class="d-flex justify-content-between" style="padding-top: 50px">
        <div class="d-flex flex-column">
          <strong class="mt-2">Notes: </strong><p>{{ invoice.note }}</p>
        </div>
        <div class="d-flex">
          <div style="min-width: 97px;">
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Total Amount</p>
            </div>
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Discount</p>
            </div>
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Payment Applied</p>
            </div>
            <div class="d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">Outstanding Balance</p>
            </div>
          </div>
          <div style="width: 186px;">
            <div class="border d-flex py-1 px-2 justify-content-end border-top-0">
              <p class="my-auto">฿{{ (totalAmount).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
            <div class="border d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">฿{{ Math.floor(totalDiscount).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
            <div class="border d-flex py-1 px-2 justify-content-end">
              <p class="my-auto">฿{{ invoice.payment_applied.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
            <div class="border d-flex py-1 px-2 justify-content-end">
              <p class="my-auto"><strong>฿{{ ((totalAmount) - Math.floor(totalDiscount) - invoice.payment_applied).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-5" style="padding-top: 50px">
        <div class="mx-auto">
          <p class="border-bottom border-secondary" style="width: 168px;"></p>
          <p class="text-center" style="margin-top: -10px;">Provider's Signature</p>
        </div>
      </div>
      <div class="mt-4 text-center text-secondary" style="border-bottom: 1px solid #d1d1d1;padding-top: 50px">
        <p>WORLDMED CENTER</p>
        <p style="margin-top: -12px;">125/131-133, M.7 Phi Phi Island, T.Aonang, A.MuangKrabi, Krabi, 81210</p>
        <p style="margin-top: -12px;">Thailand 095-424-0444 & 075-601-444 www.worldmed.center</p>
      </div>
    </section>
  </div>
</template>

<script>
// import invoice from '@/assets/print-form/invoice.json'
import HidePriceProductTable from '../../components/Table/HidePriceProductTable.vue'
import {db} from '../../db'
export default {
  data() {
    return {
      invoice: null
    }
  },
  async mounted() {
    document.body.className = "A4";
    console.log(this.$route.params.invoiceId)

    let invoiceData = await db.collection('Invoice').doc(this.$route.params.invoiceId).get()
    console.log(invoiceData.data())
    this.invoice = {
      ...invoiceData.data()
    }
  },
  computed: {
    totalDiscount() {
      const invoice = this.invoice
      const sum_product = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      const sum_billable_items = _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      const result =  sum_product + sum_billable_items
      const result2Digit = result ? result.toFixed(2) : 0
      return Number(result2Digit)
    },
    totalAmount() {
      const invoice = this.invoice
      const sumTotalAmount = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price))
        }
      })) + _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price))
        }
      }))
      const result2Digit = sumTotalAmount ? sumTotalAmount.toFixed(2) : 0
      return Number(result2Digit)
    }
  }
}
</script>

<style lang="css" scoped>
.invoice-form {
  font-size: 13px;
}
.invoice-form-table {
  font-size: 11px;
}
.table th, .table td {
  padding: .3rem;
}
@page {
    size: A4;
    margin: 5mm 0mm ;
}
.sheet {
    overflow: visible;
    height: auto !important;
}
</style>
