<template>
  <div class="mt-4">
    <div class="w-25 ">      
        There are {{ no_payments }} payments between {{ start }} and {{ end }}
    </div>
    <div class="w-25 ">      
      <h5 class="card-title">Summary of payments by type</h5>     
      <vue-good-table :columns="paymentColumns" :rows="sum_payments" :sort-options="{
    enabled: false,}">
     <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'amount'">
        {{ props.row.amount.toLocaleString() }}
      </span>
     </template>
    </vue-good-table>
    </div>
    <div class="d-flex mb-2">
      <h3 class="text-primary">Payment</h3>
      <a target="_blank" :href="`/print/payments?start=${start}&end=${end}`" class="btn btn-light ml-auto"><i
          class="fas fa-print"></i> Print Payment Summary</a>
      <!-- <button class="btn btn-light ml-auto" @click="$emit('displaySummaryModal')" tabs v-if="!$route.meta.print">
        <i class="fas fa-print"></i> Print Payment Summary
      </button> -->
    </div>

    <payment-table :data="payments" @displayPaymentModal="showPayment" />
  </div>
</template>

<script>
import { db } from '../../../db'
import { capitalizeFirstChar } from '../../../helper'

export default {
  props: ['start', 'end'],
  data() {
    return {
      payments: [],
      no_payments: 0,
      paymentColumns: [
        {
          label: "Payment type",
          field: "payment_type",
          thClass: 'text-center',
          
        },
        {
          label: "Amount",
          field: "amount",
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      sum_payments: [
        {
          payment_type:'Credit/Debit card',
          amount:0,
        },{
          payment_type:'Cash',
          amount:0,
        },{
          payment_type:'Insurance GOP',
          amount:0,
        },{
          payment_type:'Internet banking',
          amount:0,
        },{
          payment_type:'Other',
          amount:0,
        },
        {
          payment_type:'Total',
          amount:0,
        }
      ],
    }
  },
  mounted() {
    this.fetchPayments()    
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const start = this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate()
      const end = this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate()
      const payments = []

      const paymentsCollection = await db.collection('Payment')
        .where('issue_date', '>', start)
        .where('issue_date', '<', end)
        .get()
      this.no_payments = paymentsCollection.docs.length
      for (let i = 0; i < paymentsCollection.docs.length; i++) {
        const paymentCollection = paymentsCollection.docs[i]
        const payment = paymentCollection.data()
        const preparedPayments = []
        if (payment.cash > 0) {
          preparedPayments.push({ name: 'Cash', amount: payment.cash })
        }
        if (payment.credit > 0) {
          preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        }
        if (payment.insurance > 0) {
          preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        }
        if (payment.internet_banking > 0) {
          preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        }
        if (payment.other > 0) {
          preparedPayments.push({
            name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other
          })
        }
        payment.total_amount = [payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking].reduce((sum, value) => sum + Number(value || 0), 0)
        let invoice = (
          await db
            .collection("Invoice")
            .where("invoice_id", "==", payment.invoice_id)
            .get()
        ).docs.map((invoice) => invoice.data())

        payment.visit_date = invoice[0].visit_date

        payment.preparedPayments = preparedPayments
        payments.push(payment)
      }
      this.initDataforTable(payments)
      const paymentsByDate = _.groupBy(payments, payment => {
        return this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY");
      });

      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments
      }));
      
      loader.hide()
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },
    initDataforTable(payments){
      
      payments.forEach(payment=>{
        this.sum_payments[0].amount += payment.credit
        this.sum_payments[1].amount += payment.cash
        this.sum_payments[2].amount += payment.insurance
        this.sum_payments[3].amount += payment.internet_banking
        this.sum_payments[4].amount += payment.other
      })
      this.sum_payments[5].amount = this.sum_payments[0].amount+this.sum_payments[1].amount+this.sum_payments[2].amount+this.sum_payments[3].amount+this.sum_payments[4].amount
      
    },
    
    
  }
}
</script>