<template>
  <div class="mt-4">
    <h5 class="text-primary">Payment Method: Others</h5>
    <hr>
    <div class="card shadow">
      <div class="card-body row">
        <div class="col-md-6 col-sm-12">
          <table class="table table-bordered table-striped">
            <thead>
              <th colspan="4" class="text-center">Other Payment Gateway</th>
            </thead>
            <thead>
              <th>Type</th>
              <th class="text-right">Amount</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in preparedData" :key="'inter'+index">
                <td>{{item.other_name ? item.other_name : 'Other'}}</td>
                <td class="text-right" >{{Math.round(item.amount).toLocaleString()}}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="text-right">{{  Math.round( _.sumBy(preparedData, d => d.amount) ).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="d-flex mt-2 mb-2">
            <h5 class="text-primary">Other Payment Gateway</h5>
            <button 
              class="btn btn-primary ml-auto" 
              @click="$bvModal.show('payment-other-setting')">
                <i class="fas fa-cog"></i>
            </button>
          </div>
          <Bar  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'other-graph'"
            :width="400" :height="400" />
          
          <modal-graph-color-setting 
            @change="saveColor"
            v-model="colorList" 
            :id="'payment-other-setting'" 
            :legends="legendList"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Bar 
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Bar,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'PaymentOtherGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'top',
            formatter: value => {
              return Math.round(value).toLocaleString()
            }
          },
          legend: {
            display: false
          },
        },
        layout: {
          padding: {
            top: 50,
          },
        }
      },
      preparedPayments: []
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.other_name))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'other_name'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.other_name))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'other_name'))
      },
      immediate: true
    },
  },
  computed: {
    preparedData() {
      let data = [
        {
          other_name: 'Internet Banking',
          amount: 0,
          index: 0
        }
      ]

      for (let item of this.payments) {
        if(item.internet_banking > 0 ) {
          data[0].amount += item.internet_banking
        }
        if(item.other > 0) {
          if(!item.other_name) {
            item.other_name = 'Other'
          }
          let findIndex = this._.findIndex(data, {other_name: item.other_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.other
          }else{
            data.push({
              other_name: item.other_name,
              amount: 0+item.other,
              index: data.length
            })
          }
        }
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: this.preparedData.map(d => d.other_name),
        datasets: [{
          label: 'Other',
          data: this.preparedData.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>