<template>
    <div class="container-fluid">
        <div class="d-flex">
            <h3 class="text-primary">Product Summary</h3>
            <button class="btn btn-primary ml-auto" @click="showStockItem()">
                <i class="fas fa-plus"></i> Add
            </button>
        </div>

        <vue-good-table :columns="stockColumns" :rows="stocks" @on-row-click="showStockItem"
            :pagination-options="{ enabled: true, }" class="stock-table" :search-options="{ enabled: true }"
            :sort-options="{ enabled: false }">
            <template v-slot:table-row="props">
                <span v-if="props.column.field === 'expire_date'">
                    {{ formatDate(props.row.expire_date) }}
                </span>
                <span v-else-if="props.column.field === 'branch'">
                    {{ props.row.branch === 0 ? 'Main Stock' : $store.state.branches[props.row.branch - 1]?.name_en }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>


        </vue-good-table>

        <b-modal id="stock-item-modal" hide-footer size="lm" :title="'Stock Item'" no-close-on-backdrop>
            <div class="row">

                <div class="col-12 mt-2">
                    <div v-if="videoInputDevices.length > 1" id="sourceSelectPanel">
                        <label for="sourceSelect">Select Camera: </label>
                        <select v-model="selectedDeviceId" id="sourceSelect">
                            <option v-for="device in videoInputDevices" :key="device.deviceId" :value="device.deviceId">
                                {{ device.label }}
                            </option>
                        </select>
                    </div>

                    <button @click="startScan" id="startButton" class="float-left">Start</button>
                    <button @click="resetScan" id="resetScan" class="float-right">Stop</button>

                    <div class="d-flex justify-content-center">
                        <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
                    </div>
                    <label>Result:</label>
                    <pre><code> {{ scanResult }}</code></pre>
                    
                    <div>
                        {{ stock.item_code }}
                    </div>
                    <MazInput :placeholder="'Barcode'" v-model="stock.barcode" />
                </div>
                <div class="col-12 mt-2">
                    <v-select class="ml-auto" v-model="stock.item_code" :options="prepared_medicines" label="Item name"
                        :reduce="item => item.item_code" searchable filterable :filter="filterProduct">
                        <template v-slot:option="option">
                            <div>
                                <span>{{ option.item_name }}</span><br>
                                <span v-if="option.item_other_name"
                                    style="font-size: smaller; text-decoration: underline;">
                                    {{ option.item_other_name }}
                                </span>
                            </div>
                        </template>

                        <template v-slot:selected-option="option">
                            <div>
                                <span style="display: block;">{{ option.item_name }}</span>
                                <span v-if="option.item_other_name"
                                    style="font-size: smaller; text-decoration: underline; display: block;">
                                    {{ option.item_other_name }}
                                </span>
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Quantity'" v-model="stock.quantity" />
                </div>
                <div class="col-12 mt-2">
                    <MazPicker v-model="stock.expire_date" :formatted="`DD/MM/YYYY`" placeholder="Expire Date"
                        no-time />
                </div>
                <div class="col-12 mt-2">
                    <select v-model="stock.branch" class="form-control form-select">
                        <option :value="0">Main Stock</option>
                        <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}
                        </option>
                    </select>
                </div>

                <div class="col-12 mt-2">
                    <button class="btn btn-primary btn-block" @click="saveStock">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
                <div class="col-12 mt-2" v-if="isEditStock">
                    <button class="btn btn-danger btn-block" @click="deleteStockItem(stock.id)">
                        <i class="fas fa-trash"></i> Delete Stock Item
                        {{ stock.item_code }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { db, storage } from "../../db"
import { BrowserMultiFormatReader, ZXing } from '@zxing/library'
import moment from "moment"



export default {
    data() {
        return {
            medicines: [], // Data from your database
            stocks: [], // Data from your database
            stock: {},
            isEditStock: false,
            stockColumns: [
                { label: "Item Code", field: "item_code" },
                { label: "Item Name", field: "item_name" },
                { label: "Branch", field: "branch" },
                {
                    label: "Expire Date", field: "expire_date",
                    tdClass: (row) => { return this.changeColorClass(row.expire_date) }
                },
                { label: "Barcode", field: "barcode" },
                { label: "Quantity", field: "quantity", thClass: 'text-right', tdClass: 'text-right' },
            ],
            codeReader: null,               // ZXing code reader instance
            videoInputDevices: [],          // List of video input devices (cameras)
            selectedDeviceId: '',           // ID of the selected camera
            scanResult: '',                 // Result of QR/Barcode scan
            barcodes: [],                   // Data barcodes
            stream: null,
        }
    },
    firestore() {
        return {
            stocks: db.collection("Stock").orderBy('branch', 'asc').orderBy('expire_date', 'asc'),
            medicines: db.collection("Product").orderBy('item_code', 'asc'),
        }
    },
    mounted() {


    },
    watch: {
        'stock.item_code': function (newItemCode) {
            const selectedMedicine = this.medicines.find(medicine => medicine.item_code === newItemCode)
            if (selectedMedicine) {
                this.stock.item_code = selectedMedicine.item_code
                this.stock.item_name = selectedMedicine.item_name
                this.stock.item_other_name = selectedMedicine.item_other_name || ''
            } else {
                this.stock.item_code = ''
                this.stock.item_name = ''
                this.stock.item_other_name = ''
            }
        },
        'scanResult': function (val) {
            this.stock.barcode = val
            if (val !== '') {
                const selectedMedicine = this.medicines.find(medicine => {
                    const valStr = String(val);
                    if (Array.isArray(medicine.barcode)) {
                        return medicine.barcode.some(code => String(code) === valStr);
                    } else {
                        return String(medicine.barcode).includes(valStr);
                    }
                });

                if (selectedMedicine) {
                    this.stock.item_code = selectedMedicine.item_code;
                    this.stock.item_name = selectedMedicine.item_name;
                    this.stock.item_other_name = selectedMedicine.item_other_name || '';
                } else {
                    this.stock.item_code = '';
                    this.stock.item_name = '';
                    this.stock.item_other_name = '';
                }
            }
        },
        'stock.barcode': function (val) {
            if (val !== '') {
                const selectedMedicine = this.medicines.find(medicine => {
                    const valStr = String(val);
                    if (Array.isArray(medicine.barcode)) {
                        return medicine.barcode.some(code => String(code) === valStr);
                    } else {
                        return String(medicine.barcode).includes(valStr);
                    }
                });

                if (selectedMedicine) {
                    this.stock.item_code = selectedMedicine.item_code;
                    this.stock.item_name = selectedMedicine.item_name;
                    this.stock.item_other_name = selectedMedicine.item_other_name || '';
                } else {
                    this.stock.item_code = '';
                    this.stock.item_name = '';
                    this.stock.item_other_name = '';
                }
            }

        },


    },
    methods: {
        changeColorClass(value) {
            let givenDate = moment(value, "YYYY-MM-DD hh:mm A")
            let currentDate = moment()
            let diffInDays = givenDate.diff(currentDate, 'days')
            if (diffInDays < 10) {
                return 'bg-red'
            } else if (diffInDays >= 10 && diffInDays < 30) {
                return 'bg-yellow'
            } else {
                return ''
            }

        },
        formatDate(dateString) {
            return moment(dateString).format("DD/MM/YYYY")
        },
        showStockItem(params) {
            if (params) {
                this.stock = { ...params.row }
                this.isEditStock = true
            } else {
                this.stock = { branch: 0, }
                this.isEditStock = false

            }
            this.$bvModal.show("stock-item-modal")
        },
        async saveStock() {
            let loader = this.$loading.show({})
            if (!this.stock.item_code || !this.stock.quantity || !this.stock.expire_date) {
                this.$alert("please enter item code and quantity and expire_date", null, "error")
                loader.hide()
                return
            }
            try {
                let stock = { ...this.stock }

                const stocklogRef = await db.collection('Stock')
                    .where('item_code', '==', stock.item_code)
                    .where('expire_date', '==', stock.expire_date)
                    .where('branch', '==', stock.branch)
                    .get()

                if (!stocklogRef.empty) {
                    let doc = stocklogRef.docs[0]
                    let product = doc.data()
                    let updatedQuantity = (Number(product.quantity) || 0) + Number(stock.quantity)
                    await db.collection('Stock').doc(doc.id).update({ quantity: updatedQuantity })
                } else {
                    const stockRef = await db.collection("Stock").add(stock)
                    await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })
                }

                loader.hide()
                this.$alert("success", null, "success")
                this.$bvModal.hide("stock-item-modal")

                const result = await db.collection('Product').doc(this.stock.item_code).get()
                const data = result.data()
                const updatedQuantity = (Number(data.quantity) || 0) + this.stock.quantity
                const payload = {
                    ...data,
                    quantity: updatedQuantity
                }
                try {
                    await db.collection('Product').doc(this.stock.item_code).set(payload)
                } catch (error) {
                    console.log(error)
                }

            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }

        },
        deleteStockItem(id) {
            this.$confirm("do you want to delete this stock?").then(
                async () => {
                    let loader = this.$loading.show({})
                    await db.collection("Stock").doc(id).delete()
                    const result = await db.collection('Product').doc(this.stock.item_code).get()
                    const data = result.data()
                    const updatedQuantity = (Number(data.quantity) || 0) - this.stock.quantity
                    const payload = {
                        ...data,
                        quantity: updatedQuantity
                    }
                    try {
                        await db.collection('Product').doc(this.stock.item_code).set(payload)
                    } catch (error) {
                        console.log(error)
                    }
                    loader.hide()
                    this.$alert(`This Stock was deleted.`, null, "success")
                    this.$bvModal.hide("stock-item-modal")
                }
            )


        },
        async initializeCodeReader() {
            try {
                this.codeReader = new BrowserMultiFormatReader()
                //console.log('ZXing code reader initialized')

                // Get video input devices
                const devices = await this.codeReader.listVideoInputDevices()
                this.videoInputDevices = devices

                // Set the default selected device to the first camera
                if (devices.length > 0) {
                    this.selectedDeviceId = devices[0].deviceId
                }

                //console.log('Video input devices:', devices)
            } catch (err) {
                console.error('Error initializing the code reader:', err)
            }
        },
        // Start scanning for QR code/barcode from the selected camera
        startScan() {
            this.requestCameraPermission()
            this.initializeCodeReader()
            if (this.selectedDeviceId) {
                const constraints = {
                    video: {
                        deviceId: { exact: this.selectedDeviceId }, // Use the selected device
                        facingMode: { ideal: 'environment' } // Prefer rear camera on mobile
                    }
                }
                this.codeReader.decodeFromVideoDevice(this.selectedDeviceId, 'video', (result, err) => {
                    if (result) {
                        this.scanResult = result.text
                        this.resetScan()
                    }
                })
            } else {
                console.error('No camera selected')
            }
        },

        // Reset the scanner and clear the result
        resetScan() {
            this.beforeDestroy()
            //this.scanResult = ''
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop())
                this.stream = null
            }
        },
        async requestCameraPermission() {
            try {
                // Request camera access explicitly
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' } // Rear camera
                    }
                })
            } catch (error) {
                alert('Please allow camera access in your device settings or browser.')
            }
        },
        beforeDestroy() {
            if (this.codeReader) {
                this.codeReader.reset() // Reset the code reader when the component is destroyed
            }
        },
        filterProduct(options, search) {
            return options.filter(option => {
                const label = option.item_name + ' ' + option.item_other_name
                return this.filterProductBy(option, label, search)
            });
        },
        filterProductBy(_, label, search) {
            return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        },
    },
    computed: {
        prepared_medicines() {
            return this.medicines.map(i => {
                return {
                    item_name: i.item_name || null,
                    item_code: i.item_code || null,
                    item_other_name: i.item_other_name || '',
                    barcode: i.barcode || [],
                }
            })
        },
    },

}
</script>
<style lang="scss">
.stock-table {
    .bg-red {
        background-color: #FF4C4C;
        color: white;
    }

    .bg-yellow {
        background-color: #FFEB3B;
        color: black;
    }
}
</style>