<template>
  <div class="container-fluid">
    <div>
      <div>
        <div class="bg-gray">
          <div class="p-3">
            <label class="label-b"
              ><i class="fas fa-stethoscope"></i> Vital Sign & Evaluation</label
            >
            <table class="table table-bordered">
              <thead class="text-center" style="background-color: #4472c4;">
                <tr class="text-white">
                  <th rowspan="2">Time</th>
                  <th colspan="2">BP</th>
                  <th rowspan="2" style="width: 80px;">
                    PR
                    <br />/min
                  </th>
                  <th rowspan="2" style="width: 80px;">
                    RR
                    <br />/min
                  </th>
                  <th rowspan="2" style="min-width: 60px;">
                    Temp
                    <br />°C
                  </th>
                  <th rowspan="2" style="width: 80px;">
                    O2Sat
                    <br />%
                  </th>
                  <th rowspan="2" style="width: 80px;">
                    Pain
                    <br />Score
                  </th>
                  <th rowspan="2" style="width: 150px;">
                    Pain
                    <br />Location
                  </th>
                  <th rowspan="2" style="min-width: 70px;">DTX (mg/dl)</th>
                  <th colspan="3" style="width: 70px;">GCS</th>
                  <th colspan="4" style="width: 250px;">Pupil</th>
                </tr>
                <tr class="text-center">
                  <th class="text-white">Sys</th>
                  <th class="text-white">Dias</th>
                  <th class="text-black" style="background-color: #cdd4ea;">
                    E
                  </th>
                  <th class="text-black" style="background-color: #cdd4ea;">
                    V
                  </th>
                  <th class="text-black" style="background-color: #cdd4ea;">
                    M
                  </th>
                  <th
                    class="text-black"
                    style="background-color: #cdd4ea; width: 50px;"
                  >
                    Lt
                  </th>
                  <th
                    class="text-black"
                    style="background-color: #cdd4ea;"
                  ></th>
                  <th
                    class="text-black"
                    style="background-color: #cdd4ea; width: 50px;"
                  >
                    Rt
                  </th>
                  <th
                    class="text-black"
                    style="background-color: #cdd4ea;"
                  ></th>
                </tr>
              </thead>
              <tbody class="text-center" style="background-color: #d0cece;">
                <tr
                  v-for="(i, index) in nursing.vital_sign"
                  :key="`${i}_${index}`"
                >
                  <td>{{ i.time }}</td>
                  <td
                    :class="
                      checkBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)
                    "
                  >
                    {{ i.bp_syst }}
                  </td>
                  <td :class="checkBPDias(i.bp_dias, patient.ageMonth)">
                    {{ i.bp_dias }}
                  </td>
                  <td :class="checkHR(i.pr, patient.ageMonth)">
                    {{ i.pr }}
                  </td>
                  <td :class="checkRR(i.rr, patient.ageMonth)">
                    {{ i.rr }}
                  </td>
                  <td :class="checkTemp(i.temp, i.temp_unit)">
                    <div v-if="i.temp_unit === 'C'">
                      {{ i.temp }} {{ i.temp_unit }}
                    </div>
                    <div v-if="i.temp_unit === 'F'">
                      {{
                        i.temp && i.temp_unit
                          ? getTemp(i.temp, i.temp_unit)
                          : null
                      }}
                    </div>
                  </td>
                  <td :class="checkO2Sat(i.o2sat)">
                    {{ i.o2sat }}
                  </td>
                  <td :class="checkPainScore(i.pain_score)">
                    {{ i.pain_score }}
                  </td>
                  <td>{{ i.pain_location }}</td>
                  <td :class="checkDTX(i.dtx, i.dtx_unit)">
                    <div v-if="i.dtx_unit === 'mg/dl'">
                      {{ i.dtx }} {{ i.dtx_unit }}<br />
                    </div>
                    <div v-if="i.dtx_unit === 'mmol/L'">
                      {{
                        i.dtx && i.dtx_unit ? getDtx(i.dtx, i.dtx_unit) : null
                      }}
                    </div>
                  </td>
                  <td :class="checkGcsE(i.vs_e)">
                    {{ i.vs_e }}
                  </td>
                  <td :class="checkGcsV(i.vs_v)">
                    {{ i.vs_v }}
                  </td>
                  <td :class="checkGcsM(i.vs_m)">
                    {{ i.vs_m }}
                  </td>
                  <td>{{ i.pupil_lt_value }}</td>
                  <td>{{ i.pupil_lt }}</td>
                  <td>{{ i.pupil_rt_value }}</td>
                  <td>{{ i.pupil_rt }}</td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-xl-6">
                <span class="label-b"
                  ><i class="fas fa-user-nurse"></i> Pregnancy</span
                >
                <div class="d-flex mt-3">
                  <div>
                    <div>
                      <b>{{ nursing.is_pregnancy === "na" ? "N/A" : null }}</b>
                      <b class="text-capitalize">{{
                        nursing.is_pregnancy !== "na"
                          ? nursing.is_pregnancy
                          : null
                      }}</b>
                    </div>
                    <div
                      v-if="
                        nursing.pregnancy_ga_wk &&
                          nursing.pregnancy_ga_day &&
                          nursing.is_pregnancy === 'yes'
                      "
                    >
                      <b
                        >Gestational Age = {{ nursing.pregnancy_ga_wk
                        }}<span> + </span>
                        {{ nursing.pregnancy_ga_day }} weeks</b
                      >
                    </div>
                    <div
                      v-if="
                        nursing.pregnancy_ga_wk &&
                          !nursing.pregnancy_ga_day &&
                          nursing.is_pregnancy === 'yes'
                      "
                    >
                      <b
                        >Gestational Age =
                        {{ nursing.pregnancy_ga_wk }} weeks</b
                      >
                    </div>
                    <div
                      v-if="
                        !nursing.pregnancy_ga_wk &&
                          nursing.pregnancy_ga_day &&
                          nursing.is_pregnancy === 'yes'
                      "
                    >
                      <b
                        >Gestational Age =
                        {{ nursing.pregnancy_ga_day }} days</b
                      >
                    </div>
                    <div v-if="nursing.is_pregnancy === 'yes'">
                      <b
                        >By :
                        {{
                          nursing.pregnancy_by ? nursing.pregnancy_by : "-"
                        }}</b
                      >
                    </div>
                    <div>
                      <b
                        >LMP :
                        {{
                          nursing.pregnancy_lmp ? nursing.pregnancy_lmp : "-"
                        }}</b
                      >
                    </div>
                    <div>
                      <b
                        >Contraception :
                        {{
                          nursing.pregancy_contraception
                            ? nursing.pregancy_contraception
                            : "-"
                        }}</b
                      >
                    </div>
                  </div>
                  <div class="pl-5" v-if="nursing.is_pregnancy === 'yes'">
                    <div>
                      <b
                        >Gravida =
                        {{ nursing.pregnancy_g ? nursing.pregnancy_g : "-" }}</b
                      >
                    </div>
                    <div>
                      <b
                        >Parity =
                        {{ nursing.pregnancy_p ? nursing.pregnancy_p : "-" }}</b
                      >
                    </div>
                    <div>
                      <b
                        >Abortion =
                        {{ nursing.pregnancy_a ? nursing.pregnancy_a : "-" }}</b
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <span class="label-b"
                  ><i class="fa fa-male" aria-hidden="true"></i> Body
                  Measurements</span
                >
                <div class="d-flex mt-3">
                  <div>
                    <div>
                      <b
                        >Height = {{ nursing.height ? nursing.height : "-" }}
                        {{ nursing.height_unit }}</b
                      >
                      <span class="text-muted" v-if="getHeight">
                        {{ getHeight }}</span
                      >
                    </div>
                    <div>
                      <b
                        >Weight = {{ nursing.weight ? nursing.weight : "-" }}
                        {{ nursing.weight_unit }}</b
                      >
                      <span class="text-muted" v-if="getWeight">
                        {{ getWeight }}</span
                      >
                    </div>
                    <div>
                      <b>BMI = {{ getBmi() ? getBmi() : "-" }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="bg-red">
          <div class="p-3">
            <div class="mt-2">
              <i class="input-icon-red fa fa-exclamation-triangle"></i>
              <label class="label-b" style="margin-left: 10px;">
                Allergies
                <span style="color: #b30000;">(4)</span>
              </label>
              <span class="pl-4"><a href="#">Hide</a></span>
              <div>
                Drug Allergy
                <span class="pl-5" style="color: #b30000;">
                  <b class="text-capitalize">{{ nursing.is_drug_allergy }}</b>
                </span>
              </div>
            </div>
            <table class="table table-bordered mt-2">
              <thead
                class="text-center text-white"
                style="background-color: #4472c4;"
              >
                <tr>
                  <th width="50%">Allergy</th>
                  <th width="50%">Reaction</th>
                </tr>
              </thead>
              <tbody class="text-center" style="background-color: #d0cece;">
                <tr v-for="(i, index) in nursing.drug_allergy" :key="index">
                  <td>{{ i.name }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="mt-2">
              <div>
                Others
                <span class="pl-5" style="color: #b30000;">
                  <b class="text-capitalize">{{ nursing.is_other_allergy }}</b>
                </span>
              </div>
            </div>
            <table class="table table-bordered mt-2">
              <thead
                class="text-center text-white"
                style="background-color: #4472c4;"
              >
                <tr>
                  <th width="50%">Allergy</th>
                  <th width="50%">Reaction</th>
                </tr>
              </thead>
              <tbody class="text-center" style="background-color: #d0cece;">
                <tr v-for="(i, index) in nursing.other_allergy" :key="index">
                  <td>{{ i.other_allergen }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div class="bg-gray">
          <div class="p-3">
            <div class="d-flex">
              <div class="flex-1 d-flex">
                <div class="flex-1"><b>Mode of Arrival:</b></div>
                <div class="flex-1 text-muted">
                  {{ nursing.mode_of_arrival }}
                </div>
              </div>
              <div class="flex-1 d-flex">
                <div class="flex-1"><b>Purpose:</b></div>
                <div class="flex-2 text-muted">
                  {{
                    nursing.purpose === "Ambulance pick up from"
                      ? `${nursing.purpose} ${nursing.purpose_amb_pick_from}`
                      : nursing.purpose
                  }}
                </div>
              </div>
              <div class="flex-1 d-flex">
                <div class="flex-1 pl-3"><b>General Appearance:</b></div>
                <div class="flex-1 text-muted">
                  {{
                    nursing.general_appearance !== "Other"
                      ? nursing.general_appearance
                      : nursing.general_appearance_other
                  }}
                </div>
              </div>
              <div class="flex-1"></div>
            </div>
            <div class="d-flex mt-2">
              <div class="flex-1 d-flex">
                <div class="flex-1"><b>Fall Precaution:</b></div>
                <div class="flex-1 text-muted">
                  {{ nursing.fall_precaution }}
                </div>
              </div>
              <div class="flex-1 d-flex">
                <div class="flex-1"><b>Escort:</b></div>
                <div class="flex-2 text-muted">
                  {{
                    nursing.escort !== "Others"
                      ? nursing.escort
                      : nursing.escort_other_input
                  }}
                </div>
              </div>
              <div class="flex-1"></div>
              <div class="flex-1"></div>
            </div>
            <label class="label-b mt-2"
              ><i class="fas fa-id-card-alt"></i> Past History
              <span style="color: #b30000;">(8)</span></label
            >
            <span class="pl-4"><a href="#">Hide</a></span>
            <div class="row">
              <div class="col-xl-6">
                <div class="d-flex">
                  <div class="flex-1"><b>Underlying: </b></div>
                  <div class="flex-3">
                    <span
                      class="text-muted"
                      v-for="(i, index) in nursing.underlying"
                      :key="index"
                    >
                      {{
                        i.underlying_name !== "Other"
                          ? i.underlying_name
                          : i.underlying_input
                      }}
                      <span v-if="index < nursing.underlying.length - 1"
                        >,
                      </span>
                    </span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1"><b>Surgery: </b></div>
                  <div class="flex-3">
                    <span
                      class="text-muted"
                      v-for="(i, index) in nursing.surgery"
                      :key="index"
                    >
                      {{ i.surgery_name }}
                      <span v-if="index < nursing.surgery.length - 1">, </span>
                    </span>
                  </div>
                </div>
                <div>
                  <b>Current Medication: </b>
                  <div>
                    <span
                      class="text-muted"
                      v-for="(i, index) in nursing.current_medication"
                      :key="index"
                    >
                      {{ i.medication }} {{ i.medication_unit }}
                      <span v-if="index < nursing.current_medication.length - 1"
                        >,
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div>
                  <div class="d-flex">
                    <div class="flex-1"><b>Family:</b></div>
                    <div class="flex-4">
                      <span
                        class="text-muted"
                        v-for="(i, index) in nursing.family"
                        :key="index"
                      >
                        {{
                          i.family_history !== "Other"
                            ? i.family_history
                            : i.family_history_other
                        }}
                        <span
                          v-if="i.family_relation || i.family_relation_other"
                          >({{
                            i.family_relation !== "Other"
                              ? i.family_relation
                              : i.family_relation_other
                          }})
                        </span>
                        <span v-if="index < nursing.family.length - 1">, </span>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1"><b>Social:</b></div>
                    <div class="flex-4">
                      <div>
                        <b>Smoking</b>
                        <span class="text-muted pl-5"
                          >{{ nursing.smoking_value }}
                          {{ nursing.smoking_unit }}</span
                        >
                      </div>
                      <div>
                        <b>Alcohol</b>
                        <span class="text-muted pl-5"
                          >{{ nursing.alcohol_value }}
                          {{ nursing.alcohol_unit }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1"><b>Vaccination:</b></div>
                    <div class="flex-4">
                      <div>
                        <b>Tetanus</b>
                        <span class="text-muted pl-5"
                          >{{ nursing.tetanus }}
                          <span v-if="nursing.tetanus_more">
                            ({{ nursing.tetanus_more }})</span
                          ></span
                        >
                      </div>
                      <div>
                        <b>Rabies</b>
                        <span
                          class="text-muted"
                          v-bind:class="{ 'pl-5': index === 0 }"
                          v-for="(i, index) in nursing.rabies"
                          :key="index"
                        >
                          {{ i.rabies_value }}
                          <span v-if="i.rabies_note">
                            ({{ i.rabies_note }})</span
                          >
                          <span v-if="index < nursing.rabies.length - 1"
                            >,
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="bg-general">
          <div class="p-3">
            <label class="label-b mt-2"
              ><i class="fas fa-first-aid"></i> Chief Complaint</label
            >
            <table class="table table-bordered mt-2">
              <thead
                class="text-center text-white"
                style="background-color: #4472c4;"
              >
                <tr>
                  <th>Duration before arrival</th>
                  <th>Complaint</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody class="text-center" style="background-color: #d0cece;">
                <tr v-for="(i, index) in nursing.cc" :key="index">
                  <td>{{ i.cc_duration }}</td>
                  <td>
                    {{ i.cc_name !== "other" ? i.cc_name : i.cc_name_other }}
                  </td>
                  <td>{{ i.cc_note }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pl-5 pt-3">
              <img src="/images/Body-chart.png" height="500" />
            </div>
            <div class="mt-4">
              <div>
                <span class="label-b">
                  <i class="fas fa-cloud-upload-alt"></i> Upload Files
                </span>
              </div>
              <div
                class="row mt-2"
                v-for="(file, index) in nursing.files"
                :key="index"
              >
                <div class="col-2">
                  <a :href="file.url" target="_blank"
                    ><i class="far fa-file"></i> {{ file.name }}</a
                  >
                </div>
                <div class="col-2">
                  <div class="form-control">
                    {{ nursing.files[index].type }}
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-control">
                    {{ nursing.files[index].name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 text-center">
              <div class="box-border bg-white" stlye="border-radius: 0px;">
                <span class="p-4"><b>Assessed By: RN STAFF NAME</b></span>
              </div>
              <div class="text-right">
                <span class="label-b"><a href="#">Hide All</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nurseAssessmentMixins from "../mixins/nurse-assessment";
import vitalSignMixins from "../mixins/vital-sign";

export default {
  mixins: [nurseAssessmentMixins, vitalSignMixins],
  props: ['patient']
};
</script>
<style lang="scss" scoped>
.vital-red {
  background-color: #dc0000ff;
  color: #fff;
}
.vital-mid-red {
  background-color: #ea9999;
}
.vital-soft-red {
  background-color: #f4cccc;
}
.center {
  text-align: center;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.label-buildin {
  position: relative;
  display: block;
  padding-right: 76px;
  width: 135px;
  box-sizing: border-box;
}
</style>
