import { db, storage } from "../db";
import dd_escort from "../assets/escort.json";
import dd_family_relation from "../assets/family_relation.json";
import dd_duration_unit from "../assets/duration_unit.json";
import dd_o2sat from "../assets/o2sat.json";

export default {
  firestore() {
    return {
      dd_allergy_food: db
        .collection("OtherAllergy")
        .where("group", "==", "food"),
      dd_allergy_envi: db
        .collection("OtherAllergy")
        .where("group", "==", "environmental"),
      dd_allergy_contact: db
        .collection("OtherAllergy")
        .where("group", "==", "contact")
    };
  },
  data() {
    return {
      dd_allergy_food: [],
      dd_sup: [],
      dd_escort,
      dd_family_relation,
      dd_duration_unit,
      dd_o2sat,
      // drug_allergy,
      is_nursing: false,
      nursing: {
        body_part_image: null,
        body_part_url: null,
        PM: null,
        file_name: null,
        file_upload: null,
        file: "",
        time_to_arrival: new Date(),
        time_to_physician: null,
        esi_level: null,
        vital_sign: [
          {
            time: null,
            bp_syst: null,
            bp_dias: null,
            pr: null,
            rr: null,
            temp: null,
            temp_unit: null,
            temp_cal: null,
            o2sat: null,
            pain_score: null,
            pain_location: null,
            vs_e: null,
            vs_v: null,
            vs_m: null,
            pupil_lt_value: null,
            pupil_lt: null,
            pupil_rt_value: null,
            pupil_rt: null,
            dtx: null,
            dtx_unit: null
          }
        ],
        is_pregnancy: null,
        pregnancy_g: null,
        pregnancy_p: null,
        pregnancy_a: null,
        pregnancy_ga_wk: null,
        pregnancy_ga_day: null,
        pregnancy_by: null,
        pregnancy_lmp: null,
        pregancy_contraception: null,
        pregancy_contraception_other_input: null,
        height: null,
        height_unit: null,
        weight: null,
        weight_unit: null,
        bmi: null,
        allergy_cnt: 0,
        is_drug_allergy: "no",
        drug_allergy: [
          {
            name: null,
            reaction: null
          }
        ],
        // drug_allergy: [''],
        is_other_allergy: "no",
        other_allergy: [
          {
            other_allergen: null,
            other_reaction: null,
            other_allergy_pop: null
          }
        ],
        mode_of_arrival: null,
        purpose: null,
        purpose_amb_pick_from: null,
        general_appearance: null,
        general_appearance_other: null,
        fall_precaution: null,
        escort: null,
        escort_other_input: null,
        underlying: [
          {
            underlying_name: null,
            underlying_input: null
          }
        ],
        surgery: [
          {
            surgery_name: null
          }
        ],
        family: [
          {
            family_history: null,
            family_history_other: null,
            family_relation: null,
            family_relation_other: null
          }
        ],
        is_smoking: "no",
        smoking_value: null,
        smoking_unit: null,
        is_alcohol: "no",
        alcohol_value: null,
        alcohol_unit: null,
        current_medication: [
          {
            medication: null,
            medication_unit: null
          }
        ],
        tetanus: null,
        tetanus_more: null,
        rabies: [
          {
            rabies_value: null,
            rabies_note: null,
            rabies_other: null
          }
        ],
        cc: [
          {
            cc_duration_times: null,
            cc_duration_unit: null,
            cc_name: null,
            cc_note: null,
            cc_name_other: null
          }
        ],
        body_drawing_urls: [{}],
        rn_name: null,
        files: [],
        organ_pic_files: []
      },
      bodyDrawingFile: {}
    };
  },
  computed: {
    getWeight() {
      if (this.nursing.weight) {
        if (!isNaN(this.nursing.weight) && this.nursing.weight_unit === "lb") {
          return (
            "(" +
            (this.nursing.weight / 2.2).toFixed(2) +
            " kg " +
            " , " +
            (this.nursing.weight / (2.2 * 6.35)).toFixed(2) +
            " st)"
          );
        }
        if (!isNaN(this.nursing.weight) && this.nursing.weight_unit === "st") {
          return (
            "(" +
            (this.nursing.weight * 6.35).toFixed(2) +
            " kg " +
            " , " +
            (this.nursing.weight * (2.2 * 6.35)).toFixed(2) +
            " lb)"
          );
        }
        if (!isNaN(this.nursing.weight) && this.nursing.weight_unit === "kg") {
          return (
            "(" +
            (this.nursing.weight * 2.2).toFixed(2) +
            " lb" +
            " , " +
            (this.nursing.weight / 6.35).toFixed(2) +
            " st)"
          );
        }
      }
    },
    getHeight() {
      if (this.nursing.height) {
        if (!isNaN(this.nursing.height) && this.nursing.height_unit === "ft") {
          return "(" + (this.nursing.height * 30.48).toFixed(2) + " cm)";
        }
        if (!isNaN(this.nursing.height) && this.nursing.height_unit === "cm") {
          return "(" + (this.nursing.height / 30.48).toFixed(2) + " ft)";
        }
      }
    }
  },

  async created() {
    let loader = this.$loading.show({});
    let visitId = await this.$route.params.visitId;
    let nursing = await db
      .collection("NursingAssessment")
      .where("visit", "==", db.collection("Visit").doc(visitId))
      .limit(1)
      .get();
    if (nursing.docs.length > 0) {
      this.is_nursing = true;
      let nursingFetch = nursing.docs[0].data();
      this.nursing = {
        ...nursingFetch
      };
    }
    if (!this.nursing.rn_name && this.$store.getters.user.displayName)
      this.nursing.rn_name = this.$store.getters.user.displayName;
    loader.hide();
  },

  methods: {
    async saveNursingAssessment() {
      let loader = this.$loading.show({});
      try {
        if (!this.nursing.PM) this.nursing.PM = await this.generatePM();
        let HN = this.$route.params.id;
        let visitId = this.$route.params.visitId;
        //save drawing images
        // for (let index in this.bodyDrawingFile) {
        //   if (this.bodyDrawingFile[index]) {
        //     let ref = storage
        //       .ref()
        //       .child(
        //         `documents/${HN}/${visitId}/nursing-assessment/drawing${index}.png`
        //       );
        //     await ref.putString(this.bodyDrawingFile[index], "data_url");
        //     let url = await ref.getDownloadURL();
        //     this.nursing.body_drawing_urls[index] = url;
        //   }
        // }

        let data = {
          visit: db.collection("Visit").doc(visitId),
          visit_date: new Date(),
          updated_at: new Date(),
          ...this.nursing
        };

        let image_data = this.$refs['nurse_drawer'].saveImage()
        if (image_data) {
          let body_part_path = `documents/${HN}/${visitId}/nursing-assessment/nurse-drawing.png`;
          let body_part_ref = storage.ref().child(body_part_path)
          let result = await body_part_ref.putString(image_data, 'data_url', {contentType:'image/png'})
          let body_part_url = await body_part_ref.getDownloadURL()
          data.body_part_image_url = body_part_url
        }

        data.esi_level = data.esi_level ? Number(data.esi_level) : null
        if (data.is_pregnancy === "na" || data.is_pregnancy === "no") {
          data.pregnancy_g = null;
          data.pregnancy_p = null;
          data.pregnancy_a = null;
          data.pregnancy_ga_wk = null;
          data.pregnancy_ga_day = null;
          data.pregnancy_by = null;
        }
        if (!data.is_drug_allergy) data.drug_allergy = [];
        if (!data.is_other_allergy) data.other_allergy = [];
        if (!data.is_smoking) {
          data.smoking_value = null;
          data.smoking_unit = null;
        }
        if (!data.is_alcohol) {
          data.alcohol_value = null;
          data.alcohol_unit = null;
        }

        await db
          .collection("NursingAssessment")
          .doc(this.nursing.PM)
          .set(data, { merge: true });
        loader.hide();
        await this.$alert(
          "Nurse Assessment",
          `HN:${HN}\nVN:${visitId}\nSave Success`,
          "success"
        );
      } catch (error) {
        console.log(error);
        loader.hide();
        await this.$alert("Register Failed", error.message, "error");
      }
      // location.reload();
    },
    async generatePM() {
      let nursingAssessmentDB = await db.collection("NursingAssessment").get();
      let latestData = nursingAssessmentDB.docs.length
        .toString()
        .padStart("3", "0");
      return `FRM-01-${latestData}-${this.$moment().format("DDMMYYYY")}`;
    },

    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      console.log("index is " + index);
      if (modelGrp && model && index) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
      // if (modelGrp && model && !index) {
      //   this[modelGrp][model].splice(1)
      // }
    },
    async handleFile(e) {
      let loader = this.$loading.show({});
      let file = e.target.files[0];
      let ref = storage.ref();
      try {
        let fileRef = `documents/HN-${this.$route.params.id}/VN-${this.$route.params.visitId}/nursing/file/${file.name}`;
        await ref.child(fileRef).put(file);
        let url = await ref.child(fileRef).getDownloadURL();
        this.nursing.files.push({
          url,
          name: file.name,
          type: "document"
        });
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },
    async handleOrganFile(e) {
      let loader = this.$loading.show({});
      let file = e.target.files[0];
      let ref = storage.ref();
      try {
        console.log("try");
        let fileRef = `documents/HN-${this.$route.params.id}/VN-${this.$route.params.visitId}/nursing/organ/${file.name}`;
        await ref.child(fileRef).put(file);
        let url = await ref.child(fileRef).getDownloadURL();
        this.nursing.organ_pic_files.push({
          url,
          name: file.name,
          type: "image"
        });
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },


    async pushDD() {
      let dataDB;
      let latestData;
      let code;
      dataDB = await db.collection("Supply").get();
      let len = dataDB.docs.length;

      for (var i = 0; i <= this.dd_sup.length - 1; i++) {
        latestData = len.toString().padStart("3", "0");
        code = `SPY${latestData}`;
        await db
          .collection("Supply")
          .doc(code)
          .set({
            id: code,
            product_code: this.dd_sup[i].test1 ? this.dd_sup[i].test1 : null,
            brand_name: this.dd_sup[i].test2 ? this.dd_sup[i].test2 : null,
            price: this.dd_sup[i].unit ? parseInt(this.dd_sup[i].unit) : null
          });
        len++;
        if (i === this.dd_sup.length - 1) {
          alert("Done!");
        }
      }
    },

    getDtx(dtx, dtx_unit) {
      if (!isNaN(dtx) && dtx_unit === "mg/dl") {
        let dtx_number = Number(dtx);
        let mmol = (dtx_number * 0.056).toFixed(3);
        return mmol + " mmol/L";
      }
      if (!isNaN(dtx) && dtx_unit === "mmol/L") {
        let dtx_number = Number(dtx);
        let mg = (dtx_number * 18.02).toFixed(2);
        return mg + " mg/dl";
      }
    },

    getTemp(temp, temp_unit) {
      var temp_number = Number(temp);
      if (!isNaN(temp_number) && temp_unit === "F") {
        let res = ((temp_number - 32) * (5 / 9)).toFixed(2);
        return res + " °C";
      }
      if (!isNaN(temp_number) && temp_unit === "C") {
        let res = (temp_number * (9 / 5) + 32).toFixed(2);
        return res + " °F";
      }
    },

    getBmi() {
      if (
        !isNaN(this.nursing.height) &&
        !isNaN(this.nursing.weight) &&
        this.nursing.height &&
        this.nursing.weight &&
        this.nursing.height_unit &&
        this.nursing.weight_unit
      ) {
        if (this.nursing.height_unit === "cm") {
          if (this.nursing.weight_unit === "kg") {
            return (
              this.nursing.weight /
              (this.nursing.height * 0.01) ** 2
            ).toFixed(2);
          } else if (this.nursing.weight_unit === "lb") {
            let kg = this.nursing.weight / 2.2;
            return (kg / (this.nursing.height * 0.01) ** 2).toFixed(2);
          } else if (this.nursing.weight_unit === "st") {
            let kg = this.nursing.weight * 6.35;
            return (kg / (this.nursing.height * 0.01) ** 2).toFixed(2);
          } else {
            return null;
          }
        } else if (this.nursing.height_unit === "ft") {
          let ft = this.nursing.height * 30.48;
          if (this.nursing.weight_unit === "kg") {
            return (this.nursing.weight / (ft * 0.01) ** 2).toFixed(2);
          } else if (this.nursing.weight_unit === "lb") {
            let kg = this.nursing.weight / 2.2;
            return (kg / (ft * 0.01) ** 2).toFixed(2);
          } else if (this.nursing.weight_unit === "st") {
            let kg = this.nursing.weight * 6.35;
            return (kg / (ft * 0.01) ** 2).toFixed(2);
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
};
