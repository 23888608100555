var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"1em"}},[_c('b-nav',{attrs:{"tabs":"","justified":""}},[_c('b-nav-item',{attrs:{"active":this.$route.name === 'General Info',"to":{ name: 'General Info', params: { id: this.$route.params.id } }}},[_vm._v("General Info")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Visit Summary' ||
          _vm.$route.meta.visit_active === true,"to":{ name: 'Visit Summary', params: { id: this.$route.params.id } }}},[_vm._v("Visit Summary")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Patient Billing Summary',"to":{ name: 'Patient Billing Summary', params: { id: this.$route.params.id } }}},[_vm._v("Billing")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Investigation Summary' ||
          _vm.$route.meta.invest_active === true,"to":{
        name: 'Investigation Summary',
        params: { id: this.$route.params.id }
      }},on:{"click":_vm.InvestSumClick}},[_vm._v("Investigation Summary")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Admission Summary',"to":{
        name: 'Admission Summary',
        params: { id: this.$route.params.id }
      }}},[_vm._v("Admission Summary")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Operation Summary',"to":{
        name: 'Operation Summary',
        params: { id: this.$route.params.id }
      }}},[_vm._v("Operation Summary")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Documents',"to":{ name: 'Documents', params: { id: this.$route.params.id } }}},[_vm._v("Documents")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Patient Appointments' ||
          _vm.$route.meta.appt_active === true,"to":{
        name: 'Patient Appointments',
        params: { id: this.$route.params.id }
      }},on:{"click":_vm.appointmentClick}},[_vm._v("Appointments")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Medicines',"to":{ name: 'Medicines', params: { id: this.$route.params.id } }}},[_vm._v("Medicines")]),_c('b-nav-item',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":"","active":this.$route.name === 'Others',"to":{ name: 'Others', params: { id: this.$route.params.id } }}},[_vm._v("Others")])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view',{staticClass:"mt-2",attrs:{"patient":_vm.patient}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }