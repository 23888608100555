<template>
    <div class="container-fluid" id="element-to-convert">
        <div class="row">
            <h3 class="text-primary">Transfer Report</h3>
        </div>

        <div class="row mt-2">
            <MazPicker v-model="filterDate" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" placeholder="Date Range"
                range />
            <div class="d-flex mx-4">
                <label class="text-primary d-flex mx-4">From</label>
                <select v-model="frombranch" class="form-control form-select d-flex">
                    <option :value="0">Main Stock</option>
                    <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}
                    </option>
                </select>
            </div>
            <div class="d-flex mx-4">
                <label class="text-primary d-flex mx-4">To</label>
                <select v-model="tobranch" class="form-control form-select d-flex">
                    <option :value="0">Main Stock</option>
                    <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}
                    </option>
                </select>
            </div>
            <div class="text-center d-flex mx-3">
                <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="search">
                    <i class="fas fa-search"></i> Search
                </button>
            </div>

            <div class="text-center d-flex mx-3">
                <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="exportCsv">
                    <i class="fas fa-file-excel"></i> Export CSV
                </button>
            </div>
            <div class="text-center d-flex mx-3">
                <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="printReport">
                    <i class="fas fa-file-pdf"></i> Print
                </button>
            </div>
        </div>

        <div class="row mt-4 d-flex flex-column">
            <vue-good-table :columns="transferColumns" :rows="transfers" :use-dynamic-row-height="true" :sort-options="{
                enabled: false,
            }" :group-options="{
                enabled: false,
            }" :pagination-options="{
                dropdownAllowAll: false,
            }">
                <template slot="table-row" slot-scope="props">

                    <div v-if="props.column.field == 'item_name'" style="display: block;">
                        <span style="display: block;">{{ props.row.item_name }}</span>
                        <span style="font-size: smaller; text-decoration: underline; display: block;">
                            {{ props.row.item_other_name }}
                        </span>                 
                    </div>
           
                </template>
            </vue-good-table>
        </div>


    </div>
</template>

<script>
import { db } from "../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
    data() {
        return {
            filterDate: {
                start: this.$moment(new Date(), "DD/MM/YYYY"),
                end: this.$moment(new Date(), "DD/MM/YYYY"),
            },
            tobranch: null,
            frombranch: null,
            transfers: [],
            transferColumns: [
                {
                    label: "Item code",
                    field: "item_code",
                },
                {
                    label: "Item name",
                    field: "item_name",
                },                
                {
                    label: "Transfer",
                    field: "transfer",
                    formatFn: (value) => value.toLocaleString(),
                    thClass: 'text-right',
                    tdClass: 'text-right',
                },

            ],

        }
    },
    firestore() {
        return {
            medicines: db.collection("Product").orderBy('item_code', 'asc'),
        }
    },
    methods: {
        printReport() {
            const moment = require('moment')
            let start = ""
            let end = ""
            if (moment.isMoment(this.filterDate.start)) {
                start = this.$moment(this.filterDate.start).format('DD/MM/YYYY')
                end = this.$moment(this.filterDate.end).format('DD/MM/YYYY')
            }
            else {
                start = this.filterDate.start
                end = this.filterDate.end
            }
            let frombranch = this.frombranch
            let tobranch = this.tobranch
            window.open(`/print/transfer-report/?start=${start}&end=${end}&from=${frombranch}&to=${tobranch}`)
        },
        exportCsv() {
            try {
                let datas = this.transfers
                const SumData = datas.map((data) => ({
                    Item_code: data.item_code,
                    Item_name: data.item_name,
                    Generic_name: data.item_other_name,
                    Transfer: data.transfer,
                }))
                const parser = new Parser()
                const myData = JSON.parse(JSON.stringify(SumData))
                const csv = parser.parse(myData)
                const anchor = document.createElement("a")
                anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
                anchor.target = "_blank"
                anchor.download = "transfer.csv"
                anchor.click()
                anchor.remove()
            } catch (err) {
                console.error(err)
            }
        },
        async search() {
            const loader = this.$loading.show({})
            await this.fetchTransfers(
                this.$moment(this.filterDate.start, "DD/MM/YYYY").startOf("day").format("DD/MM/YYYY HH:mm"),
                this.$moment(this.filterDate.end, "DD/MM/YYYY").endOf("day").format("DD/MM/YYYY HH:mm"),
            )

            loader.hide()

        },
        async fetchTransfers(start, end,) {
            
            let products = []
            const transferRef = await db
                .collection("Transfer")
                .where("issue_date", ">=", start)
                .where("issue_date", "<", end)
                .where("frombranch", "==", this.frombranch)
                .where("tobranch", "==", this.tobranch)
                .get()

            transferRef.docs.forEach((transfer) => {
                const data = transfer.data()
                products = products.concat(this.getName(data.product_items))


            })
            const transferRef2 = await db
                .collection("Transfer")
                .where("issue_date", ">=", start)
                .where("issue_date", "<", end)
                .where("frombranch", "==", this.tobranch)
                .where("tobranch", "==", this.frombranch)
                .get()


            transferRef2.docs.forEach((transfer) => {
                const data = transfer.data()
                products = products.concat(this.getName2(data.product_items))
            })

            const formattedProducts = _.chain(products)
                .groupBy("item_code")
                .map((product, code) => ({
                    item_code: code,
                    item_name: product[0].item_name,
                    item_other_name: product[0].item_other_name,
                    transfer: _.sumBy(product, "transfer"),
                }))
            
            this.transfers = formattedProducts.value()

            this.transfers.sort((a, b) => {
                if (a.item_code < b.item_code) return -1
                if (a.item_code > b.item_code) return 1
            })


        },
        getName(services) {
            if (!services || !services.length) return []
            return services.map((item) => {
                const selectedMedicine = this.medicines.find(medicine => medicine.item_code === item.item_code)
                return {
                    item_code: item.item_code,
                    item_name: selectedMedicine.item_name,
                    item_other_name: selectedMedicine.item_other_name,
                    transfer: item.transfer,
                }
            })
        },
        getName2(services) {
            if (!services || !services.length) return []
            return services.map((item) => {
                const selectedMedicine = this.medicines.find(medicine => medicine.item_code === item.item_code)
                return {
                    item_code: item.item_code,
                    item_name: selectedMedicine.item_name,
                    item_other_name: selectedMedicine.item_other_name,
                    transfer: -item.transfer,
                }
            })
        },


    },
    computed: {

    },
}
</script>
