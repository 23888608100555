<template>
  <div class="row mt-2">
    <div class="col-md-6 col-sm-12">
      <table class="table table-bordered table-striped">
        <thead>
          <th colspan="4" class="text-center">Other Payment Gateway</th>
        </thead>
        <thead>
          <th>Name</th>
          <th>Payment Type</th>
          <th>Time</th>
          <th class="text-right">Amount</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedPayments" :key="'other-payment-'+index">
            <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank" rel="noopener noreferrer">{{item.patient_name}}</a></td>
            <td v-if="item.internet_banking">Internet Banking</td>
            <td v-else>{{item.other_name ? item.other_name : 'Other'}}</td>
            <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm a')}}</td>
            <td v-if="item.internet_banking" class="text-right" >{{item.internet_banking.toLocaleString()}}</td>
            <td v-else class="text-right" >{{item.other.toLocaleString()}}</td>
          </tr>
          <tr>
            <td colspan="3">Total</td>
            <td class="text-right" >{{ _.sumBy(filteredPayments, p => p.other ).toLocaleString()}}</td>
          </tr>
        </tbody>
      </table>
      <table-pagination :items="filteredPayments" :onChange="(items) => { paginatedPayments = items}" :perPage="10"/>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="d-flex mt-2 mb-2">
        <h5 class="text-primary mr-auto">Other Payment Gateway</h5>
        <button class="btn btn-primary ml-auto" @click="$bvModal.show('other-payment-gateway-country-setting')"><i class="fas fa-cog"></i></button>
      </div>
      <Bar  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'other-graph'"
        :width="400" :height="400" />

      <modal-graph-color-setting 
        @change="saveColor"
        v-model="colorList" 
        :id="'other-payment-gateway-country-setting'" 
        :legends="legendList"/>
    </div>
  </div>
</template>

<script>
import {
  Bar 
} from 'vue-chartjs/legacy'
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Bar,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'OtherPaymentGatewayGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'top',
            formatter: value => {
              return value.toLocaleString()
            }
          },
          legend: {
            display: false
          },
        },
        layout: {
          padding: {
            top: 50,
          },
        }
      },
      preparedPayments: [],
      paginatedPayments: []
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.data.map( item => item.other_name))
        this.setColorList(this.generateBackgroundColor(this.data, 'other_name'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.data.map( item => item.other_name))
        this.setColorList(this.generateBackgroundColor(this.data, 'other_name'))
      },
      immediate: true
    },
  },
  computed: {
    filteredPayments() {
      let data = []
      for(let item of this.payments) {
        if(item.other > 0) {
          data.push({
            payment_id: item.payment_id,
            patient_name: item.patient_name,
            other: item.other,
            other_name: item.other_name,
            issue_date: item.issue_date
          })
        }
        if(item.internet_banking > 0) {
          data.push({
            payment_id: item.payment_id,
            patient_name: item.patient_name,
            internet_banking: item.internet_banking,
            issue_date: item.issue_date
          })
        }
      }
      console.log({data})
      return data
      // return [...this._.filter(this.payments, p => p.other>0)]
    },
    otherPayments() {
      return _.filter(this.payments, p => p.other>0 )
    },
    internetBankingPayments() {
      return _.filter(this.payments, p => p.internet_banking>0 )
    },
    data() {
      let data = [
        { 
          other_name: 'Internet Banking',
          amount: 0,
          index: 0
        }
      ]

      for (let item of this.filteredPayments) {
        if(item.internet_banking > 0 ) {
          data[0].amount += item.internet_banking
        }
        if(item.other > 0) {
          if(!item.other_name) {
            item.other_name = 'Other'
          }
          let findIndex = this._.findIndex(data, {other_name: item.other_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.other
          }else{
            data.push({
              other_name: item.other_name,
              amount: 0+item.other,
              index: data.length
            })
          }
        }
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: this.data.map(d => d.other_name),
        datasets: [{
          label: 'Other',
          data: this.data.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>