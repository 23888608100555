import Billing from '../pages/Billing/index.vue'
import BillingDashboard from '../pages/Billing/Dashboard.vue'
import BillingSummary from '../pages/Billing/Summary'
import Overall from '../pages/Billing/Dashboard/Overall.vue'
import Cash from '../pages/Billing/Dashboard/Cash.vue'
import Card from '../pages/Billing/Dashboard/Card.vue'
import Others from '../pages/Billing/Dashboard/Others.vue'
import Insurance from '../pages/Billing/Dashboard/Insurance.vue'
// import ExpenseDashboard from '../pages/Billing/Expense/Dashboard.vue'
// import ExpenseSummary from '../pages/Billing/Expense/Summary.vue'
export default [
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    redirect: {
      name: "Billing Summary"
    },
    children: [
      {
        path: "billing-dashboard",
        name: "Billing Dashboard",
        component: BillingDashboard,
        redirect: {
          name: "Billing Dashboard Overall"
        },
        children: [
          {
            path: "",
            name: "Billing Dashboard Overall",
            component: Overall,
          },
          {
            path: "cash",
            name: "Billing Dashboard Cash",
            component: Cash,
          },
          {
            path: "card",
            name: "Billing Dashboard Card",
            component: Card,
          },
          {
            path: "others",
            name: "Billing Dashboard Others",
            component: Others,
          },
          {
            path: "insurance",
            name: "Billing Dashboard Insurance",
            component: Insurance,
          }
        ]
      },
      {
        path: 'billing-summary',
        name: 'Billing Summary',
        component: BillingSummary
      },
      // {
      //   path: "expense-dashboard",
      //   name: "Expense Dashboard",
      //   component: ExpenseDashboard
      // },
      // {
      //   path: "expense-summary",
      //   name: "Expense Summary",
      //   component: ExpenseSummary
      // },
    ]
  }
];
