import { db, storage } from "../db";
import moment from "moment";
import _ from "lodash";
import visit from './visit'
export default {
  mixins: [visit],
  data() {
    return {
      patient: {
        HN: null,
        branch: this.$store.state.branch,
        visit_date: new Date(),
        blood_group: null,
        img_url: null,
        title: null,
        first_name: null,
        last_name: null,
        date_of_birth: null,
        gender: "",
        marital_status: null,
        nationality: null,
        passport_id: null,
        occupation: null,
        occupation_other: null,
        religion: null,
        religion_other: null,
        phone_th: null,
        phone_inter: null,
        phone: null,
        email: null,
        symptom: null,
        is_allergy: false,
        allergies: [""],
        is_other_allergy: false,
        other_allergies: [""],
        home_address: null,
        home_city: null,
        home_country: null,
        home_zipcode: null,
        is_tourist: false,
        arrival_date: null,
        departure_date: null,
        tourist_hotel: null,
        tourist_hotel_other: null,
        is_resident: false,
        resident_workplace: null,
        resident_workplace_other: null,
        resident_hotel: null,
        resident_hotel_other: null,
        emergency_first_name: null,
        emergency_last_name: null,
        emergency_relationship: null,
        emergency_phone: null,
        is_emergency_address_same_home: false,
        emergency_address: null,
        emergency_city: null,
        emergency_country: null,
        emergency_zipcode: null,
        has_travel_insurance: null,
        travel_insurance: null,
        travel_insurance_other: null,
        how_did_you_find_us: [],
        note: null,
        is_certify: false,
        signature_url: null,
        certify_relation: null,
        certify_relation_other: null,
        rejected_at: null,
        rejected_by: null
      }
    };
  },
  methods: {
    async savePatient(branchId, redirect_path_name = "Select Registration") {
      // let current_patients
      // if (!this.patient.HN) [this.patient.HN, current_patients] = await this.generateHN(branchId);
      // console.log("🚀 ~ savePatient ~ branchId:", branchId)
      let loader = this.$loading.show({});

      if (!this.patient.has_travel_insurance) {
        this.$alert('Please enter your insurance information.', null, 'error')
        loader.hide()
        return
      }

      // if(this.patient.has_travel_insurance && !this.patient.travel_insurance) {
      //   this.$alert('Please enter your insurance information.', null, 'error')
      //   loader.hide()
      //   return
      // }
      if (!this.patient.is_resident && !this.patient.is_tourist) {
        this.$alert('Please enter your type of stay in Phiphi.', null, 'error')
        loader.hide()
        return
        
      }
      if (this.patient.is_resident && this.patient.is_tourist) {
        this.$alert('Please select only one type of stay in phiphi.', null, 'error')
        loader.hide()
        return
        
      }
      if (this.patient.is_tourist) {
        if (this.patient.arrival_date === null) {
          this.$alert('Please enter your arrival date information.', null, 'error')
          loader.hide()
          return
        }
        if (this.patient.departure_date === null) {
          this.$alert('Please enter your departure date information.', null, 'error')
          loader.hide()
          return
        }
      }

      if (this.patient.how_did_you_find_us.length === 0) {
        this.$alert('Please enter "How did you find us ?" information.', null, 'error')
        loader.hide()
        return
      }
      try {
        let current_patients
        if (!this.patient.HN) [this.patient.HN, current_patients] = await this.generateHN(branchId);

        //upload profile image
        if (this.profileFile) {
          let ext = this.profileFile.name.split(".").pop();
          let imageLocation = `images/${this.patient.HN}/profile.${ext}`;
          let profile_ref = storage.ref().child(imageLocation);
          await profile_ref.put(this.profileFile);
          this.patient.img_url = await profile_ref.getDownloadURL();
        } else if (this.profileBase64) {
          let imageLocation = `images/${this.patient.HN}/profile.jpg`;
          let profile_ref = storage.ref().child(imageLocation);
          await profile_ref.putString(this.profileBase64, 'data_url', { contentType: "image/ jpg" })
          this.patient.img_url = await profile_ref.getDownloadURL();
        }

        if (this.signatureFile) {
          let signature_ref = storage
            .ref()
            .child(`images/${this.patient.HN}/signature.png`);
          await signature_ref.putString(this.signatureFile, "data_url");

          this.patient.signature_url = await signature_ref.getDownloadURL();
        }

        let patientData = {
          ...this.patient,
          first_name_lowercase: this.patient.first_name?.toLowerCase() || null,
          last_name_lowercase: this.patient.last_name?.toLowerCase() || null,
          updated_at: new Date(),
          created_at: new Date()
        };

        //preprocess data

        if (patientData.visit_date)
          patientData.visit_date = moment(
            patientData.visit_date,
            "DD/MM/YYYY HH:mm"
          ).toDate();
        if (patientData.date_of_birth)
          patientData.date_of_birth = moment(
            patientData.date_of_birth,
            "DD/MM/YYYY"
          ).toDate();
        if (patientData.arrival_date)
          patientData.arrival_date = moment(
            patientData.arrival_date,
            "DD/MM/YYYY"
          ).toDate();
        if (patientData.departure_date)
          patientData.departure_date = moment(
            patientData.departure_date,
            "DD/MM/YYYY"
          ).toDate();

        patientData.allergies = _.compact(
          patientData.allergies.map(item => {
            if (item) {
              return db.collection("Allergy").doc(item);
            } else {
              return null;
            }
          })
        );
        patientData.other_allergies = _.compact(
          patientData.other_allergies.map(item => {
            if (item) {
              return db.collection("Allergy").doc(item);
            } else {
              return null;
            }
          })
        );

        if (
          patientData.has_travel_insurance === "yes" &&
          patientData.travel_insurance
        ) {
          patientData.travel_insurance = db
            .collection("Insurance")
            .doc(patientData.travel_insurance);
        } else {
          patientData.travel_insurance = null;
        }

        if (!patientData.is_tourist) {
          patientData.tourist_hotel = null;
        }

        if (!patientData.is_resident) {
          patientData.resident_hotel = null;
          patientData.resident_workplace = null;
        }

        if (!patientData.is_allergy) patientData.allergies = [];
        if (!patientData.is_other_allergy) patientData.other_allergies = [];

        let result = await db
          .collection("Patient")
          .doc(this.patient.HN)
          .set(patientData, { merge: true });

        //update current patient
        console.log("🚀 ~ savePatient ~ current_patients:", current_patients)
        if (branchId == 3) {
          await db
            .collection("Config")
            .doc(`WA-${this.$moment().format("YYYY")}`)
            .update({
              current_patients: current_patients
            })
        }
        if (branchId != 3) {
          await db
            .collection("Config")
            .doc(this.$moment().format("YYYY"))
            .update({
              current_patients: current_patients
            })
        }


        await this.newVisit(this.patient.HN, branchId, 1, this.$moment(patientData.visit_date, 'DD/MM/YYYY HH:mm').toDate(), 'New Patient')

        loader.hide();
        await this.$alert("Registration Success", null, "success");
        window.open(`${window.location.origin}/print/register-form/${this.patient.HN}`, '_blank');
        this.$router.push({ name: redirect_path_name });
      } catch (error) {
        console.error(error);
        loader.hide();
        await this.$alert("Register Failed", error.message, "error");
      }
    },
    async savePatientWA(branchId, redirect_path_name = "Select Registration") {
      // let current_patients
      // if (!this.patient.HN) [this.patient.HN, current_patients] = await this.generateHN(branchId);
      // console.log("🚀 ~ savePatient ~ branchId:", branchId)
      let loader = this.$loading.show({});

      if (this.patient.how_did_you_find_us.length === 0) {
        this.$alert('Please enter "How did you find us ?" information.', null, 'error')
        loader.hide()
        return
      }
      try {
        let current_patients
        if (!this.patient.HN) [this.patient.HN, current_patients] = await this.generateHN(branchId);

        //upload profile image
        if (this.profileFile) {
          let ext = this.profileFile.name.split(".").pop();
          let imageLocation = `images/${this.patient.HN}/profile.${ext}`;
          let profile_ref = storage.ref().child(imageLocation);
          await profile_ref.put(this.profileFile);
          this.patient.img_url = await profile_ref.getDownloadURL();
        } else if (this.profileBase64) {
          let imageLocation = `images/${this.patient.HN}/profile.jpg`;
          let profile_ref = storage.ref().child(imageLocation);
          await profile_ref.putString(this.profileBase64, 'data_url', { contentType: "image/ jpg" })
          this.patient.img_url = await profile_ref.getDownloadURL();
        }

        if (this.signatureFile) {
          let signature_ref = storage
            .ref()
            .child(`images/${this.patient.HN}/signature.png`);
          await signature_ref.putString(this.signatureFile, "data_url");

          this.patient.signature_url = await signature_ref.getDownloadURL();
        }

        let patientData = {
          ...this.patient,
          first_name_lowercase: this.patient.first_name?.toLowerCase() || null,
          last_name_lowercase: this.patient.last_name?.toLowerCase() || null,
          updated_at: new Date(),
          created_at: new Date()
        };

        //preprocess data

        if (patientData.visit_date)
          patientData.visit_date = moment(
            patientData.visit_date,
            "DD/MM/YYYY HH:mm"
          ).toDate();
        if (patientData.date_of_birth)
          patientData.date_of_birth = moment(
            patientData.date_of_birth,
            "DD/MM/YYYY"
          ).toDate();
        if (patientData.arrival_date)
          patientData.arrival_date = moment(
            patientData.arrival_date,
            "DD/MM/YYYY"
          ).toDate();
        if (patientData.departure_date)
          patientData.departure_date = moment(
            patientData.departure_date,
            "DD/MM/YYYY"
          ).toDate();

        patientData.allergies = _.compact(
          patientData.allergies.map(item => {
            if (item) {
              return db.collection("Allergy").doc(item);
            } else {
              return null;
            }
          })
        );
        patientData.other_allergies = _.compact(
          patientData.other_allergies.map(item => {
            if (item) {
              return db.collection("Allergy").doc(item);
            } else {
              return null;
            }
          })
        );

        if (!patientData.is_tourist) {
          patientData.tourist_hotel = null;
        }

        if (!patientData.is_resident) {
          patientData.resident_hotel = null;
          patientData.resident_workplace = null;
        }

        if (!patientData.is_allergy) patientData.allergies = [];
        if (!patientData.is_other_allergy) patientData.other_allergies = [];

        let result = await db
          .collection("Patient")
          .doc(this.patient.HN)
          .set(patientData, { merge: true });

        //update current patient
        console.log("🚀 ~ savePatient ~ current_patients:", current_patients)
        if (branchId == 3) {
          await db
            .collection("Config")
            .doc(`WA-${this.$moment().format("YYYY")}`)
            .update({
              current_patients: current_patients
            })
        }
        if (branchId != 3) {
          await db
            .collection("Config")
            .doc(this.$moment().format("YYYY"))
            .update({
              current_patients: current_patients
            })
        }


        await this.newVisit(this.patient.HN, branchId, 1, this.$moment(patientData.visit_date, 'DD/MM/YYYY HH:mm').toDate(), 'New Patient')

        loader.hide();
        await this.$alert("Registration Success", null, "success");
        window.open(`${window.location.origin}/patient/${this.patient.HN}/visit`, '_blank');
        this.$router.push({ name: redirect_path_name });
      } catch (error) {
        console.error(error);
        loader.hide();
        await this.$alert("Register Failed", error.message, "error");
      }
    },
    async updatePatient() {
      let loader = this.$loading.show({});
      //update patient

      //upload profile image
      if (this.profileFile) {
        let ext = this.profileFile.name.split(".").pop();
        let imageLocation = `images/${this.patient.HN}/profile.${ext}`;
        let profile_ref = storage.ref().child(imageLocation);
        await profile_ref.put(this.profileFile);

        this.patient.img_url = await profile_ref.getDownloadURL();
      }

      let patientData = {
        ...this.patient,
        updated_at: new Date()
      };
      console.log({ patientData })
      //preprocess data
      if (patientData.visit_date)
        patientData.visit_date = moment(
          patientData.visit_date,
          "DD/MM/YYYY HH:mm"
        ).toDate();
      if (patientData.date_of_birth)
        patientData.date_of_birth = moment(
          patientData.date_of_birth,
          "DD/MM/YYYY"
        ).toDate();
      if (patientData.arrival_date)
        patientData.arrival_date = moment(
          patientData.arrival_date,
          "DD/MM/YYYY"
        ).toDate();
      if (patientData.departure_date)
        patientData.departure_date = moment(
          patientData.departure_date,
          "DD/MM/YYYY"
        ).toDate();

      patientData.allergies = _.compact(
        patientData.allergies.map(item => {
          if (item) {
            return db.collection("Allergy").doc(item);
          } else {
            return null;
          }
        })
      );
      patientData.other_allergies = _.compact(
        patientData.other_allergies.map(item => {
          if (item) {
            return db.collection("Allergy").doc(item);
          } else {
            return null;
          }
        })
      );

      if (
        patientData.has_travel_insurance === "yes" &&
        patientData.travel_insurance
      ) {
        patientData.travel_insurance = db
          .collection("Insurance")
          .doc(patientData.travel_insurance);
      } else {
        patientData.travel_insurance = null;
      }

      if (!patientData.is_tourist) {
        patientData.tourist_hotel = null;
      }

      if (!patientData.is_resident) {
        patientData.resident_hotel = null;
        patientData.resident_workplace = null;
      }

      if (!patientData.is_allergy) patientData.allergies = [];
      if (!patientData.is_other_allergy) patientData.other_allergies = [];

      console.log('saving...', patientData.HN)
      await db
        .collection("Patient")
        .doc(patientData.HN)
        .update(patientData);
      loader.hide();
      await this.$alert(null, "Patient Update Success!", "success");
    },
    async initPatient(HN) {
      let patientSnapshot = await db
        .collection("Patient")
        .doc(HN)
        .get();
      let patientData = patientSnapshot.data();
      this.patient = {
        ...patientData
      };
    },
    async generateHN(branchId) {
      console.log("🚀 ~ generateHN ~ branchId:", branchId)
      let hn_second_part = `${new moment()
        .format("YYYY")
        .slice(-2)}${branchId}`;

      let hn_first_part;
      let hn_third_part;
      let current_patients

      try {
        if (branchId == 3) {
          let config = await db
            .collection("Config")
            .doc(`WA-${this.$moment().format('YYYY')}`)
            .get()

          current_patients = config.data().current_patients
          current_patients++
          let arr2 = current_patients.toString().split("");
          while (arr2.length < 6) {
            arr2.unshift("0");
          }
          arr2 = arr2.join("");
          hn_first_part = arr2.substring(6, 3);
          hn_third_part = arr2.substring(0, 3);
          console.log("🚀 ~ generateHN ~ `WA-${hn_first_part}-${hn_second_part}-${hn_third_part}`:", `WA-${hn_first_part}-${hn_second_part}-${hn_third_part}`)
          return [`WA-${hn_first_part}-${hn_second_part}-${hn_third_part}`, current_patients];
        }
        if (branchId !== 3) {
          let config = await db
            .collection("Config")
            .doc(`${this.$moment().format('YYYY')}`)
            .get()

          current_patients = config.data().current_patients
          current_patients++
          let arr2 = current_patients.toString().split("");
          while (arr2.length < 6) {
            arr2.unshift("0");
          }
          arr2 = arr2.join("");
          hn_first_part = arr2.substring(6, 3);
          hn_third_part = arr2.substring(0, 3);
          console.log("🚀 ~ generateHN ~ `WA-${hn_first_part}-${hn_second_part}-${hn_third_part}`:", `${hn_first_part}-${hn_second_part}-${hn_third_part}`)
          return [`${hn_first_part}-${hn_second_part}-${hn_third_part}`, current_patients];
        }
      }
      catch (error) {
        console.log(error)
        throw error
      }
    },
    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    },
    uncheckRadio(val, modelGrp, model) {
      if (val) {
        this[modelGrp][model] = false;
      }
    },
    removeAllergy(index) {
      if (index === 0) {
        this.patient.allergies = [""];
      } else {
        this.patient.allergies.splice(index, 1);
      }
    },
    removeOtherAllergy(index) {
      if (index === 0) {
        this.patient.other_allergies = [""];
      } else {
        this.patient.other_allergies.splice(index, 1);
      }
    }
  }
};
