<template>
  <div class="mt-4 mb-4">
    <h5 class="text-primary">Payment Method: Insurance</h5>
    <hr>
    <div class="card shadow">
      <div class="card-body row mt-2">
        <div class="col-md-8 col-sm-12">
          <table class="table table-bordered table-striped">
            <thead>
              <th>Name</th>
              <th>Type</th>
              <th class="text-right">Amount</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in paginatedPayments" :key="index">
                <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{ item.patient_name }}</a></td>
                <td>{{ item.type ? item.type : 'Other' }}</td>
                <td class="text-right">{{ Math.round(item.insurance).toLocaleString() }}</td>
              </tr>
              <tr>
                <td colspan="2">Total</td>
                <td class="text-right">{{ Math.round( _.sumBy(filteredPayments, item => item.insurance) ).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <table-pagination :items="filteredPayments" :onChange="(items) => { paginatedPayments = items}" :perPage="10"/>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="d-flex mt-2 mb-2">
            <h5 class="text-primary">Insurance</h5>
            <button 
              class="btn btn-primary ml-auto" 
              @click="$bvModal.show('payment-insurance-setting')">
                <i class="fas fa-cog"></i>
            </button>
          </div>
          <Doughnut :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
            :chart-id="'credit card by bank'" :width="400" :height="400" />

          <modal-graph-color-setting 
            @change="saveColor"
            v-model="colorList" 
            :id="'payment-insurance-setting'" 
            :legends="legendList"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Doughnut
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Doughnut,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'PaymentInsuranceGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              const dataset = context.dataset;
              const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              return `${Math.round(value).toLocaleString()} \n(${Math.round(percentage)}%)`;
            }
          },
          legend: {
            display: true // Set this to false to hide the legend
          },
        }
      },
      preparedPayments: [],
      paginatedPayments: []
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.type))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'type'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.type))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'type'))
      },
      immediate: true
    },
  },
  computed: {
    filteredPayments() {
      return this._.filter(this.payments, p => p.insurance > 0)
    },
    preparedData() {
      let data = [{
        type: 'OPD',
        amount: 0,
        index: 0
      }, {
        type: 'IPD',
        amount: 0,
        index: 1
      },]

      for (let item of this.payments) {
        if (item.insurance > 0) {
          if(item.type === 'OPD') {
            data[0].amount += item.insurance
          }else if(item.type === 'IPD'){
            data[1].amount += item.insurance
          }
        }
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: ['OPD','IPD'],
        datasets: [{
          label: 'Insurance',
          data: this.preparedData.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>