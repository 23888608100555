import { db, storage } from "../db";
import dd_lab from "../assets/lab.json";
import moment from "moment";
import dd_followp_recommend from '../assets/followup_recommend.json'
const reason = [
  "",
  "Financial Problem",
  "No Insurance",
  "Insurance don't cover",
  "TIme not enough",
  "Patient have own medicine",
  "Patient feels better",
  "Communication Problem"
];
const treatment_amt = ["", "A", "B", "C"];

export default {
  data() {
    return {
      dd_reason: reason,
      dd_treatment_amt: treatment_amt,
      dd_followp_recommend: dd_followp_recommend,
      temp_lab_select: [],
      temp_outlab_select: [],
      opd: {
        body_part_image: null,
        body_part_url: null,
        PM: null,
        // duration_before_arrival: null,
        // cc_duration_before_arrival: null,
        cc_duration: null,
        cc_name: null,
        cc_note: null,
        cc_name_other: null,
        present_illness: null,
        phys_exam: {
          ga_check: null,
          ga: null,
          skin_check: null,
          skin: null,
          heent_check: null,
          heent: null,
          rs_check: null,
          rs: null,
          cvs_check: null,
          cvs: null,
          abdomen_check: null,
          abdomen: null,
          extrem_check: null,
          extrem: null,
          neuro_check: null,
          neuro: null,
          phys_exam_other: null
        },
        phys_exam_photo: [
          {
            phys_exam_photo_url: null
          }
        ],
        phys_exam_body_photo: [
          {
            phys_exam_body_photo_url: null
          }
        ],
        principal_diagnosis: null,
        principal_diagnosis_note: null,
        diagnosis: [
          {
            diagnosis_name: null,
            diagnosis_note: null
          }
        ],
        investigation_type: [],
        investigation_x_ray: null,
        investigation_organ: null,
        investigation_side: null,
        investigation_note: null,
        investigation_price: null,
        laboratory_type: [],
        laboratory_price: null,
        investigation_laboratory_total_price: null,
        //investigation lab
        invest_lab: {
          invest_select: [
            {
              invest_date_time: new Date(),
              invest_type: null,
              invest_organ_view: null,
              views: [],
              show_organ_view: [],
              invest_organ_view_other: null,
              invest_side: null,
              invest_detail: null,
              // invest_price: null,
              invest_ekg_type: null,
              invest_ekg_type_other: null,
              is_against: false,
              is_side: null,
              against_reason: null
            },
          ],
          // lab_select: [],
          lab_select: [],
          outlab_select: [],
          temp_against_reason: null,
          modal_focus: null
        },
        //treatment
        treatment: {
          procedure: [
            {
              procedure_date_time: new Date(),
              procedure_name: null,
              procedure_notes: null,
              procedure_amount: null,
              procedure_price: null,
              procedure_name_other_input: null,
              is_against: false,
              against_reason: null
            }
          ],
          med_supply: [
            {
              med_supply_date_time: new Date(),
              med_supply_medicine: null,
              med_supply_dosage: null,
              med_supply_amount: null,
              med_supply_unit: null,
              med_supply_price: null,
              med_supply_other_input: null,
              is_against: false,
              against_reason: null
            }
          ],
          temp_against_reason: null,
          modal_focus: null
        },
        //component follow up
        follow_up: {
          advice: null,
          againt_med_detail: null,
          againt_med_reason: null,
          is_recommend_rest: null,
          recommend_rest: null,
          recommend_days: null,
          is_recommend_wound_dressing: false,
          recommend_wound_dressing: null,
          is_recommend_wound_dressing_other: false,
          recommend_wound: [
            {
              recommend_wound_detail: null
            }
          ],
          is_re_visit: false,
          is_follow_up: null,
          follow_up_date: null,
          follow_up_time: null,
          follow_up_department: null,
          follow_up_doctor: null,
          reason_list: [
            {
              reason: null,
              plan: null
            }
          ],
          plan_of_treatment: null,
          comment: null
          //end component follow up
        },
        refer: {},
        files: [],
        ivf: [],
        supplies: [],
        special_order: {
          special_orders: [],
          notes: []
        },
        against_medical_advice: [],
        mode_of_transfer: {
          
        }
      },
      nursing: {}
    };
  },
  async created() {
    let loader = this.$loading.show({});
    let visitId = await this.$route.params.visitId;
    // get nursing detail
    let nursing = await db
      .collection("NursingAssessment")
      .where("visit", "==", db.collection("Visit").doc(visitId))
      .limit(1)
      .get();
    if (nursing.docs.length > 0) {
      let nursingFetch = nursing.docs[0].data();
      if (nursing.docs.length > 0) {
        this.nursing = {
          ...nursingFetch
        };
      }
    }
    // get opd detail
    let opd = await db
      .collection("OpdCard")
      .where("visit", "==", db.collection("Visit").doc(visitId))
      .limit(1)
      .get();
    if (opd.docs.length > 0) {
      let opdFetch = opd.docs[0].data();
      this.opd = {
        ...opdFetch
      };
      // convert date to show
      if (this.opd.treatment.procedure) {
        this.opd.treatment.procedure.map(item => {
          item.procedure_date_time = this.$moment(
            item.procedure_date_time.toDate()
          ).format("DD/MM/YYYY HH:mm");
        });
      }
      if (this.opd.treatment.med_supply) {
        this.opd.treatment.med_supply.map(item => {
          if (item.med_supply_date_time) {
            item.med_supply_date_time = this.$moment(
              item.med_supply_date_time.toDate()
            ).format("DD/MM/YYYY HH:mm");
          }
        });
      }
      if (this.opd.invest_lab.invest_select) {
        this.opd.invest_lab.invest_select.map(item => {
          if (item.invest_date_time) {
            item.invest_date_time = this.$moment(
              item.invest_date_time.toDate()
            ).format("DD/MM/YYYY HH:mm");
          }
        });
      }
      if (this.opd.invest_lab.lab_select) {
        this.temp_lab_select = await this.opd.invest_lab.lab_select.map(item => {
          let obj = {
            lab_test: item.lab_test,
            is_input: item.is_input,
            is_sub: item.is_sub,
            group: item.group,
            lab_type: item.lab_type,
            turn_around_time: item.turn_around_time,
            specimen: item.specimen,
            sample: item.sample,
            storage_condition: item.storage_condition,
          }
          return obj
        })
      }
      if (this.opd.invest_lab.outlab_select) {
        this.temp_outlab_select = await this.opd.invest_lab.outlab_select.map(item => {
          let obj = {
            lab_test: item.lab_test,
            is_input: item.is_input,
            is_sub: item.is_sub,
            group: item.group,
            lab_type: item.lab_type,
            turn_around_time: item.turn_around_time,
            specimen: item.specimen,
            sample: item.sample,
            storage_condition: item.storage_condition,
          }
          return obj
        })
      }
    }

    console.log('[+] OPD data init', this.opd)

    loader.hide();
  },
  methods: {
    async saveOpdCard() {
      let loader = this.$loading.show({});
      try {
        if (!this.opd.PM) this.opd.PM = await this.generatePM();
        let visitId = this.$route.params.visitId;
        let data = {
          ...this.opd,
          visit: db.collection("Visit").doc(visitId),
          created_at: new Date(),
          updated_at: new Date()
        };
        // Data with condition
        if (!data.follow_up.is_recommend_wound_dressing_other) {
          data.follow_up.recommend_wound = []
        }
        // Convert Date
        if (data.treatment.procedure) {
          data.treatment.procedure.map(item => {
            if (typeof item.procedure_date_time === "string") {
              item.procedure_date_time = moment(
                item.procedure_date_time,
                "DD/MM/YYYY HH:mm"
              ).toDate();
            }
          });
        }
        if (data.treatment.med_supply) {
          data.treatment.med_supply.map(item => {
            if (typeof item.med_supply_date_time === "string") {
              item.med_supply_date_time = moment(
                item.med_supply_date_time,
                "DD/MM/YYYY HH:mm"
              ).toDate();
            }
          });
        }
        if (data.invest_lab.invest_select) {
          data.invest_lab.invest_select.map(item => {
            if (typeof item.invest_date_time === "string") {
              item.invest_date_time = moment(
                item.invest_date_time,
                "DD/MM/YYYY HH:mm"
              ).toDate();
            }
          });
        }
        
        let image_data = this.$refs['opd_drawer'].saveImage()
        if (image_data) {
          let body_part_path = `documents/HN-${this.$route.params.id}/VN-${this.$route.params.visitId}/body_part.png`;
          let body_part_ref = storage.ref().child(body_part_path)
          let result = await body_part_ref.putString(image_data, 'data_url', {contentType:'image/png'})
          let body_part_url = await body_part_ref.getDownloadURL()
          data.body_part_image_url = body_part_url
        }
        
        //log data
        console.log('[+] saving OPD data', data)

        let result = await db
          .collection("OpdCard")
          .doc(this.opd.PM)
          .set(data);
        console.log(result);
        loader.hide();
        this.$alert("OPD Save Success", null, "success");
      } catch (error) {
        console.error(error);
        loader.hide();
        await this.$alert("OPD Save Failed", error.message, "error");
      }
    },
    async generatePM() {
      let OpdCardDB = await db.collection("OpdCard").get();
      let latestData = OpdCardDB.docs.length.toString().padStart("3", "0");
      return `FRM-02-${latestData}-${this.$moment().format("DDMMYYYY")}`;
    },
    async handleFile(e) {
      let loader = this.$loading.show({});
      let file = e.target.files[0];
      let ref = storage.ref();
      try {
        let fileRef = `documents/HN-${this.$route.params.id}/VN-${this.$route.params.visitId}/${file.name}`;
        await ref.child(fileRef).put(file);
        let url = await ref.child(fileRef).getDownloadURL();
        this.opd.files.push({
          url,
          name: file.name,
          type: "document"
        });
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },

    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 0) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    },
    addChildRowList(model, value) {
      console.log('model: ', model);
      if (model) {
        console.log("add!0");
        let len = this["value"][model].length;
        let cTime = new Date();
        if (model === "procedure") {
        console.log("add!1");
        this["value"][model].push({
            procedure_date_time: null,
            procedure_name: null,
            procedure_notes: null,
            procedure_amount: null,
            procedure_price: null,
            procedure_name_other_input: null,
            is_against: false,
            against_reason: null
          });
          this["value"][model + "Length"] += value;
          this.value.procedure[len].procedure_date_time = cTime;
        }
        if (model === "med_supply") {
        console.log("add!2");
        this["value"][model].push({
            med_supply_date_time: null,
            med_supply_medicine: null,
            med_supply_dosage: null,
            med_supply_amount: null,
            med_supply_price: null,
            med_supply_other_input: null,
            is_against: false,
            against_reason: null
          });
          this["value"][model + "Length"] += value;
          this.value.med_supply[len].med_supply_date_time = cTime;
        }
        if (model === "invest_select") {
        console.log("add!3");
        this["value"][model].push({
            invest_date_time: null,
            invest_type: null,
            invest_organ_view: null,
            views: [],
            show_organ_view: [],
            invest_organ_view_other: null,
            invest_side: null,
            invest_detail: null,
            invest_ekg_type: null,
            invest_ekg_type_other: null,
            is_against: false,
            is_side: null,
            against_reason: null
          });
          this["value"][model + "Length"] += value;
          this.value.invest_select[len].invest_date_time = cTime;
        } else {
          console.log("add!");
          this["value"][model].push({});
          this["value"][model + "Length"] += value;
        }
      } 
    },
    removeChildRowList(model, index) {
      if (model && index) {
        if (this["value"][model].length > 0) {
          this["value"][model].splice(index, 1);
        }
      }
    },
    async confirmAgainstAdvice(model) {
      if (this.value[model]) {
        await this.value[model].map(item => {
          if (item.is_against && !item.against_reason) {
            item.against_reason = this.value.temp_against_reason;
          }
        });
        this.value.temp_against_reason = null;
        this.$bvModal.hide("against-advice-treatment");
        this.$bvModal.hide("against-advice-invest");
      }
    },
    againstAllItem(model) {
      if (this.value[model]) {
        this.value[model].map(item => {
          if (!item.is_against && !item.against_reason) item.is_against = true;
        });
      }
    },
    checkDisabled(model, index) {
      if (this.value[model]) {
        if (this.value[model][index].against_reason) {
          return true;
        }
      } else {
        return false;
      }
    },
    passingModal(item) {
      this.value.modal_focus = item;
    },
    checkDisabledByClass(model, index) {
      if (this.value[model]) {
        if (this.value[model][index].against_reason) {
          return 'is-against-active';
        }
      } else {
        return null;
      }
    },
    async confirmLabSelected() {
      if (this.temp_lab_select) {
        let labTestList = this.value.lab_select.map(m => m.lab_test)
        await this.temp_lab_select.map(item => {
          if (!labTestList.includes(item.lab_test)) {
            let obj = {
              lab_test: item.lab_test,
              is_input: item.is_input,
              is_sub: item.is_sub,
              group: item.group,
              lab_type: item.lab_type,
              turn_around_time: item.turn_around_time,
              specimen: item.specimen,
              sample: item.sample,
              storage_condition: item.storage_condition,
              is_against: false,
              against_reason:  null
            }
            this.value.lab_select.push(obj)
          }

        })
        let dif_lab = _.difference( this.value.lab_select.map(m => m.lab_test), this.temp_lab_select.map(m => m.lab_test))
        if (dif_lab.length > 0) {
          this.value.lab_select.map(f => {
            if (dif_lab.includes(f.lab_test)) {
              dif_lab.forEach(d => {
                _.remove(this.value.lab_select, item => 
                  item.lab_test === d
                )
              })
            }
          })
        }
        this.$bvModal.hide("select-lab-type");
      }
      this.$mount()
    },
    removeLabShow(lab) {
      if (this.value.lab_select.includes(lab)) {
        // todo delete
        _.remove(this.value.lab_select, i => {
          return i === lab
        });
        _.remove(this.temp_lab_select, j => {
          return j.lab_test === lab.lab_test
        });
        this.$mount();
      }
    },
    toggleCheck(items, item) {
      // if found item
      if (items.includes(item)) {
        // todo delete
        _.remove(items, i => i === item);
        
      } else {
        items.push(item)
      }
      this.$mount();
    },
  }
};
