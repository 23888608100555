var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",attrs:{"id":"element-to-convert"}},[_vm._m(0),_c('div',{staticClass:"row mt-2"},[_c('MazPicker',{attrs:{"no-time":"","format":"DD/MM/YYYY","formatted":"DD/MM/YYYY","placeholder":"Date Range","range":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}}),_c('div',{staticClass:"text-center d-flex mx-3"},[_c('button',{staticClass:"btn btn-primary mx-auto ml-2 px-4",on:{"click":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-search"}),_vm._v(" Search ")])]),_c('div',{staticClass:"text-center d-flex mx-3"},[_c('button',{staticClass:"btn btn-primary mx-auto ml-2 px-4",on:{"click":function($event){$event.preventDefault();return _vm.exportCsv.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-excel"}),_vm._v(" Export CSV ")])]),_c('div',{staticClass:"text-center d-flex mx-3"},[_c('button',{staticClass:"btn btn-primary mx-auto ml-2 px-4",on:{"click":function($event){$event.preventDefault();return _vm.printReport.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-pdf"}),_vm._v(" Print ")])])],1),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Summary Of Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.summaryColumns,"rows":[..._vm.serviceSummary, ..._vm.productSummary, ..._vm.totalSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
        enabled: false,
      },"group-options":{
        enabled: false,
      },"pagination-options":{
        dropdownAllowAll: false,
      }}})],1),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Service Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.serviceColumns,"rows":[..._vm.services, ..._vm.serviceSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
        enabled: false,
      },"group-options":{
        enabled: false,
      },"pagination-options":{
        dropdownAllowAll: false,
      }}})],1),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Product Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.serviceColumns,"rows":[..._vm.products, ..._vm.productSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
        enabled: false,
      },"group-options":{
        enabled: false,
      },"pagination-options":{
        dropdownAllowAll: false,
      }}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Sale Report")])])
}]

export { render, staticRenderFns }