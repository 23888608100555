<template>
  <div class="sheet p-4">
    <div class="container-fluid">
      <invoice-table :data="invoices" />
      <p class="text-grey mt-2 text-right" v-if="start && end">{{ $route.query.start }} to {{ $route.query.end }}</p>
    </div>
  </div>
</template>

<script>
import { db } from '../../db'
import { getTotalDiscount } from '../../helper/invoice'

export default {
  data() {
    return {
      invoices: [],
      start: this.$route.query.start,
      end: this.$route.query.end,
      patientId: this.$route.query.patientId,
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  methods: {
    async fetchInvoices() {
      const loader = this.$loading.show({})
      const invoices = []

      const invoicesCollection = this.patientId ?
        await db.collection('Invoice')
          .where('HN', '==', this.patientId)
          .get()
        :
        await db.collection('Invoice')
          .where('issue_date', '>', this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate())
          .where('issue_date', '<', this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate())
          .get()
      const invoicePromises = invoicesCollection.docs.map(async invoiceDoc => {
        const invoiceData = invoiceDoc.data()

        if (invoiceData && invoiceData.invoice_id) {
          const paymentsCollection = await db.collection('Payment')
            .where('invoice_id', '==', invoiceData.invoice_id)
            .get()

          const payments = paymentsCollection.docs.map(paymentDoc => paymentDoc.data())
          invoiceData.payments = payments
        }
        invoiceData.discount = Math.round(getTotalDiscount(invoiceData))

        return invoiceData
      })

      const resolvedInvoices = await Promise.all(invoicePromises)
      invoices.push(...resolvedInvoices)

      const invoicesByDate = _.groupBy(invoices, item => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY")
      })

      this.invoices = Object.entries(invoicesByDate).map(data => {
        return {
          mode: 'span',
          label: data[0],
          html: false,
          children: data[1]
        }
      })

      loader.hide()
    },
  }
}
</script>

<style type="text/css" media="print">
@page { size: landscape !important }
</style>