<template>
  <vue-good-table
    :columns="paymentColumns"
    :rows="data"
    :use-dynamic-row-height="true"
    :calculate-width-by-content="true"
    :sort-options="{
      enabled: false,
    }"
    :group-options="{
      enabled: true
    }"
    :pagination-options="{
      enabled: !this.$route.meta.print,
      enabled: true,
      perPageDropdown: [30, 40, 50, 100, 200],
      dropdownAllowAll: false,
    }"
    :search-options="{
        enabled: true,
    }" 
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'issue_date'">
        {{ props.row.issue_date.toDate() | moment('HH:mm') }}
      </span>
      <span v-else-if="props.column.field == 'payment_id'">
        <button class="btn btn-light text-white" @click="showPayment(props.row.payment_id)">
          <i class="fas fa-receipt" style='color:#0b97e5'></i>
        </button>
        
      </span>
      <span v-else-if="props.column.field == 'visit_date'">
        {{ props.row.visit_date.toDate() | moment('DD/MM/YYYY HH:mm') }}
      </span>
      <span v-else-if="props.column.field == 'patient_name'">
        <a target="_blank" :href="`/patient/${props.row.HN}`">{{ props.row.patient_name }}</a>
      </span>
      <span v-else-if="props.column.field == 'payment_type'">
        <span v-for="(p, index) in props.row.preparedPayments" :key="`type_${index}`" >
          <a href="#" @click="showPayment(props.row.payment_id)" >
            {{ index+1 }}. {{ p.name }}
          </a>
          <br />
        </span>
      </span>
      <span v-else-if="props.column.field == 'payment_amount'">
        <span v-for="(p, index) in props.row.preparedPayments" :key="`amount_${index}`" >
          <a href="#" @click="showPayment(props.row.payment_id)" >
            {{ index+1 }}. {{ p.amount.toLocaleString() }}
          </a>
          <br />
        </span>
      </span>
      <span v-else-if="props.column.field == 'payment'">
        <span v-for="(p, index) in props.row.preparedPayments" :key="`payment_${index}`" >
          <a href="#" @click="showPayment(props.row.payment_id)" >
            {{ displayPayment(p) }}
          </a>
          <br/>
        </span>
      </span>
      <span v-else-if="props.column.label == 'Total Amount'">
        <a href="#" @click="showPayment(props.row.payment_id)" >{{ props.row.total_amount.toLocaleString() }} </a>
      </span>
      <div v-else-if="props.column.label == 'Print'">
        <button class="btn btn-light text-white" >
          <a target="_blank" :href="`/print/receipt/${props.formattedRow['payment_id']}?mode=hide`"><i class="fas fa-print"></i></a>
        </button>
      </div>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      paymentColumns: [
        {
          label: 'Payment time',
          field: 'issue_date',
          thClass: 'text-center',
        },
        {
          label: 'Payment#',
          field: 'payment_id',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Visit Datetime',
          field: 'visit_date',
          thClass: 'text-center',
        },
        {
          label: 'Patient Name',
          field: 'patient_name',
          width: '200px',
          thClass: 'text-center',
        },
        // {
        //   label: 'Payment Type',
        //   field: 'payment_type',
        // },
        // {
        //   label: 'Payment Amount',
        //   field: 'payment_amount',
        //   thClass: 'text-right',
        //   tdClass: 'text-right',
        // },
        {
          label: 'Payment',
          field: 'payment',
          thClass: 'text-center',
        },
        {
          label: 'Total Amount',
          field: 'total_amount',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Print',
          field: 'print',          
          thClass: 'text-center',
          tdClass: 'text-center',
          hidden: !!this.$route.meta.print
        }
      ]
    }
  },
  mounted() {
    this.showdata()
  },
  methods: {
    showdata(){
      console.log(this.data)
    },
    displayPayment(payment){
      let paymentType = ''
      paymentType += payment.name +" "+payment.amount+" "
      return paymentType

    },
    showPayment (paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },
  }
}
</script>