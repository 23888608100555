<template>
  <MazSearch
    v-model="selectedItem"
    :items="icd10list"
    :loading="loading"
    item-text="label"
    item-vale="code"
    @request="searchICD10"
    @input="onSelect"
    placeholder="Search ICD10"
    :clearable="true"
    :replace-on-select="true"
  />
</template>

<script>
import icd10 from '../assets/icd10_codes.json'
import _ from 'lodash'

export default {
  props: ['value'],
  data() {
    return {
      icd10list: [],
      loading: false,
      selectedItem: null,
    }
  },
  methods: {
    async searchICD10(query) {
      if(query.length >= 3) {
        let result = _.filter(icd10, item => {
          return item.desc.toLowerCase().indexOf(query.toLowerCase()) !== -1
        })
  
        this.icd10list = result.map(item => {
          return {
            code: item.code,
            label: `[${item.code}] ${item.desc}`,
            desc: item.desc
          }
        })
      }else{
        this.icd10list = []
      }
    },
    onSelect() {
      this.$emit('input', this.selectedItem)
    }
  },
}
</script>