<template>
  <div class="container-fluid">
    <div class="d-flex">
      <button class="btn mr-2 shadow p-3" v-for="(menu, index) in menus" :key="`menu${index}`" @click="$router.push(menu.path)" :class="{'btn-primary': $route.path === menu.path}">
        <div><b>{{ menu.name }}</b></div>
        <div v-if="menu.name !== 'Overall'">{{ Math.round(menu.amount).toLocaleString()}}</div>
      </button>

      <button class="ml-auto btn btn-light shadow p3">
        <b>INCOME: {{ Math.round(menus[0].amount).toLocaleString() }}</b>
      </button>
    </div>
    <hr>
    <router-view :payments="filteredItems" :period="filter.period"></router-view>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  props: ['filter','period'],
  firestore(){
    return{
      payments: db.collection('Payment').orderBy("issue_date", "desc")
    }
  },
  data(){
    return{
      payments: [],
      from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      initMenus: [
        { name: 'Overall', path: '/billing/billing-dashboard', amount: 0},
        { name: 'Cash', path: '/billing/billing-dashboard/cash', amount: 0},
        { name: 'Card', path: '/billing/billing-dashboard/card', amount: 0},
        { name: 'Others', path: '/billing/billing-dashboard/others', amount: 0},
        { name: 'Insurance', path: '/billing/billing-dashboard/insurance', amount: 0}
      ]
    }
  },
  computed: {
    menus() {
      const data = this.initMenus
      data[0].amount = 0
      data[1].amount = 0
      data[2].amount = 0
      data[3].amount = 0
      data[4].amount = 0

      for(let item of this.filteredItems){
        data[0].amount += (item.cash+ item.credit+ item.insurance+item.other+ item.internet_banking)
        data[1].amount += item.cash
        data[2].amount += item.credit
        data[3].amount += item.other+item.internet_banking
        data[4].amount += item.insurance
      }

      return data
    },
    filteredItems() {
      let from = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let to = this.$moment(this.filter.to, 'DD/MM/YYYY')

      return this._.filter(this.payments, item => {
        if (item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        } else {
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    }
  }
}
</script>